<template>	
	<el-row   type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_match_info_box">
				<div class="d_title">{{systemlang=='cn'?'赛事信息':'Tour Info'}} | <span>{{systemlang!='cn'?'赛事信息':'Tour Info'}}</span></div>
			</div>
			<el-row type="flex" justify="center">
				<el-col v-if="list.fj && list.fj.length>0" :xs="24" :sm="24" :md="24" v-for="item in list.fj">
					<a target="_blank" :href="item.annex_url">
					<div class="d_title" style="border:0;font-size:0.15rem;">{{item.annex_titile}}&nbsp;&nbsp;<img style="display:inline-block;width:20px;" src="../../assets/word.png" alt="" /></div>
					</a>
					</el-col>
					

				<el-col :xs="24" :sm="24" :md="24" style="display: flex;justify-content: center;">
					<empty v-if="!list.Alert_WordUrl" >
						
					</empty>

					<iframe class="lf_iframe" style="display: flex;justify-content: center;min-height:500px;" v-if="list.Alert_WordUrl" :src="list.Alert_WordUrl" frameborder="0"  width="100%"></iframe>

					
					
				</el-col>
				
			</el-row>
		
			
		</el-col>
	</el-row>
	<form id="dataform" method="post" style="display:none;"></form>

</template>

<script>

import axios from '../../axios/request';
import empty from "../empty.vue";
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				page:{
					limit:5,
					page:1,
					allpage:0,
					count:0,
					start:0
				},
				pagevalue:1,
				aliclickshow:false,
				bFlag:'all', // all:全部订单 wsh:等待审核 wplay:等待支付 ok:已完成
				list:[],
				
				
		    }
		},
        name: 'matchInfo',
        props: {
            msg: String,
			mt_id:String,
			order_id:String,
        },
		components: {
			empty
        },
		created() {
			
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			if(this.order_id)
			{
				this.getorderid = this.order_id;
			}
			this.getData();
		},
		watch:{
			bFlag(value)
			{
				this.bFlag = value;
				this.getData();
			}
			
		},
		methods:{
			
			getData(s){
				let  param = {};
				param.mt_id = this.mt_id;
				axios('user/notvipcotent.json',param)
				.then((res)=>{
					let data = res.data;
					if(data.msgCode!='200')
					{
						this.list = [];
						
						if(s)
						{
							alert(data.msg);
						}
						
					}
					else
					{
						this.list = data.data;
						
					}
				});
			},
		}
    }
</script>

<style scoped="scoped">
	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.28rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.24rem;
	}
	.lf_match_info_box{
		padding: 0.35rem 0px;
		font-size: 0.14rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lf_match_info_box_r{
		display: flex;
		justify-content: flex-end;
	}
	.lf_course_bogo{
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}
	.lf_course_bogo_des{
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}
	.lf_course_name_data_box{
		background-color: #FAFAFA;
	}
	.lf_hide_select{
		display: none!important;
	}
	.lf_course_name_data_tit{
		width: 96%;
		border-bottom: 1px solid #efefef;
		color: #333;
		font-size: 0.16rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_con{
		width: 96%;
		color: #999;
		font-size: 0.14rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_tit div,.lf_course_name_data_con div{
		width: 12%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-direction: column;
	}
	.lf_1,.lf_4,.lf_5{
		width: 7%!important;
	}
	.lf_course_name_data_tit .lf_9{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
	}
	.lf_course_name_data_con .lf_9{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
	}
	.lf_9 text{cursor: pointer;
		color: #C0153E;
		margin-right: 0.1rem;
	}
	.lf_course_name_data_con .lf_2{
		justify-content: flex-start;
	}
	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}
	.lf_tab_little_box{
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lf_tab_little_box div {
		padding: 0 0.16rem;
		height: 0.14rem;
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
		cursor: pointer;
	}
	.lf_tab_little_box div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}
	.el-pagination{
		justify-content: center;
	}
	.block{
		margin: 0.2rem auto;
	}
	
	
	.lf_course_name_data_box_phone{
		display: flex;
		align-items: center;
		position: fixed;
		background-color: #fff;
		width: 7rem;
		border-radius: 0.1rem;
		padding: 0.2rem 0.3rem;
	}
	.lf_course_name_data_box_phone .lf_course_name_data_tit_phone{
		width: 30%;
	}
	.lf_course_name_data_box_phone .lf_course_name_data_tit_phone,.lf_course_name_data_box_phone .lf_course_name_data_con_phone{
		flex-direction: column;
		padding: 0.5rem 0;
	}
	.lf_course_name_data_box_phone .lf_course_name_data_tit_phone div,.lf_course_name_data_box_phone .lf_course_name_data_con_phone div{
		flex-direction: row;
		width: 100%!important;
		min-height: 0.6rem;
		padding: 0.1rem 0;
	}
	.lf_course_name_data_box_phone_box{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(0,0,0,0.3);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_close_img{
		width: 0.36rem;
		height: 0.36rem;
		position: absolute;
		right: 0.1rem;
		top: 0.1rem;
	}
	.lf_course_name_data_box_pay{
		display: flex;
		align-items: center;
		position: fixed;
		background-color: #fff;
		width: 5rem;
		border-radius: 0.1rem;
		padding: 0.5rem 0.3rem;
		flex-direction: column;
	}
	.lf_course_name_data_box_pay_t{
		font-size: 0.24rem;
		color: #333;
		margin: 0.2rem 0 0.1rem;
	}
	.lf_course_name_data_box_pay_M{
		font-size: 0.24rem;
		color: #333;
	}
	.lf_course_name_data_box_pay_M text{
		font-size: 0.24rem;
		color: #C0153E;
		margin-left: 0.1rem;
	}
	.lf_course_name_data_box_pay_b{
		display: flex;
		align-items: center;
		margin: 0.2rem 0;
		justify-content: space-between;
	}
	.lf_course_name_data_box_pay_b img{
		height: 0.7rem;
		width: auto;
	}
	.lf_course_name_data_box_pay_b1{
		display: flex;
		align-items: center;
		margin: 0.2rem 0;
		justify-content: center;
	}
	.lf_course_name_data_box_pay_b1 img{
		height: 3rem;
	}
	
	
	
	.lf_entry_info_box_left_con_list{
		display: flex;
		align-items: center;
		min-height: 0.5rem;
		justify-content: center;
	}
	.lf_entry_info_box_left_con_list_left{
		width: 2.5rem;
		display: flex;
		justify-content: flex-end;
		font-size: 0.16rem;
		color: #999;
		flex-shrink: 0;
	}
	.lf_entry_info_box_left_con_list_left text{
		color: #C0153E;
		display: flex;
		align-items: center;
	}
	.lf_entry_info_box_left_con_list_right{
		display: flex;
		align-items: center;
		margin-left: 0.8rem;
		font-size: 0.16rem;
	}
	.lf_entry_info_box_left_con_list_right .el-select{
		width: 6rem;
	}
	.lf_entry_info_box_left_con_list_right .el-input{
		width: 7.8rem;
		height: 0.6rem;
		background: inherit;
		background-color: rgba(239, 239, 239, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		text-align: left;
	}
	.lf_entry_info_box_left_con{
		background-color: rgba(250, 250, 250, 1);
		border: none;
		border-radius: 2px;
		padding: 1rem 0;
	}
	.lf_iframe{
		background-color: #fafafa;
		padding: 0.3rem;
		width: 17.9rem;
		height: 19.6rem;
		margin-bottom: 0.3rem;
	}
	
	@media screen and (max-width: 768px) {
		.lf_match_info_box{
			padding: 0.35rem 0px;
			margin-left: 0;
		}
		.lf_course_name_data_tit div:nth-of-type(n+3),.lf_course_name_data_con div:nth-of-type(n+3){
			display: none!important;
		}
		.lf_course_name_data_tit div.lf_9,.lf_course_name_data_con div.lf_9{
			display: flex!important;
		}
		.lf_course_name_data_tit div,.lf_course_name_data_con div{
			width: 45%!important;
		}
		.lf_course_name_data_tit .lf_1,.lf_course_name_data_con .lf_1{
			width: 10%!important;
		}
		.lf_hide_select{
			display: unset!important;
		}
		.lf_tab_little_box div {
			padding: 0 0.1rem;
			height: 0.14rem;
			font-size: 0.16rem;
			color: #999;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			border-right: 1px solid #999;
			word-break: keep-all;
		}
		.lf_course_name_data_box_phone{
			display: flex;
			align-items: center;
			position: fixed;
			background-color: #fff;
			width: 7rem;
			border-radius: 0.1rem;
			padding: 0.2rem 0.3rem;
		}
		.lf_course_name_data_box_phone .lf_course_name_data_tit_phone{
			width: 30%;
		}
		.lf_course_name_data_box_phone .lf_course_name_data_tit_phone,.lf_course_name_data_box_phone .lf_course_name_data_con_phone{
			flex-direction: column;
			padding: 0.5rem 0;
		}
		.lf_course_name_data_box_phone .lf_course_name_data_tit_phone div,.lf_course_name_data_box_phone .lf_course_name_data_con_phone div{
			flex-direction: row;
			width: 100%!important;
			min-height: 0.6rem;
			padding: 0.1rem 0;
		}
		.lf_course_name_data_box_phone_box{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.lf_close_img{
			width: 0.36rem;
			height: 0.36rem;
			position: absolute;
			right: 0.1rem;
			top: 0.1rem;
		}
		.lf_entry_info_box_left_con_list_right .el-select{
			width: 4rem;
			margin-left: 0;
		}
		.lf_entry_info_box_left_con_list_right .el-input{
			width: 4rem;
			height: 30px;
			font-size: 0.26rem;
		}
		.lf_entry_info_box_left_con_list_right{
			margin-left: 0.1rem;
			height: 0.8rem;
			font-size: 0.26rem;
		}
		.lf_entry_info_box_left_con_list_left{
			width: 1.5rem;
			font-size: 0.26rem;
		}
		.lf_iframe{
			background-color: #fafafa;
			padding: 0.3rem;
			width: 7.5rem;
			height: auto;
		}
	}
</style>
