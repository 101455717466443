<template>
	<div class="all_body">


		<div v-if="info.addhole" class="lf_match_info_box top_to">
			<div class="d_title">{{systemlang =='cn'?'加洞赛':'Playoff'}} | <span>{{systemlang=='cn'?'Playoff':'加洞赛'}}</span></div>
		</div>
		<div v-if="info.addhole" class="lf_Playoff_box top_to">
			<div class="lf_Playoff_tit">
				<div class="lf_off_1"></div>
				<div class="lf_off_2">Player</div>
				<div class="lf_off_3" v-if="info.addhole&&WebModel" v-for="b in info.addhole.round"><text  style="color: #C0153E;margin-right: 0.1rem;">#{{b}} </text>
					H{{info.addhole&&info.addhole.roundarr[b].hole?info.addhole.roundarr[b].hole:'-'}}
					Par{{info.addhole&&info.addhole.roundarr[b].par?info.addhole.roundarr[b].par:'-'}}
				</div>
				<div class="lf_off_3" v-if="info.addhole&&!WebModel" v-for="b in info.addhole.round">
					H{{info.addhole&&info.addhole.roundarr[b].hole?info.addhole.roundarr[b].hole:'-'}}
				</div>
			</div>
			<div v-for="b in info.addhole.list" class="lf_Playoff_con">
				<div class="lf_off_1 " :class="b.win =='1'?'lf_off_1_win':''">{{b.win =='1'?'WIN':'ELM'}}</div>
				<div class="lf_off_2"><img v-if="b.pl_country" :src="b.pl_country" alt="">{{b.pl_name}}</div>
				<div class="lf_off_3" v-for="s in b.hole">{{s}}</div>
			</div>

		</div>

		<div class="lf_match_info_box lf_match_info_box_s top_to">
			<div class="d_title">{{systemlang =='cn'?'球员成绩表':'Leaderboard'}} | <span>{{systemlang !='cn'?'球员成绩表':'Leaderboard'}}</span></div>
			<div  class="d_right" v-if="!isMobile() && jscj!='1'">
				<div class="search hand">
					<div class="search_pos">
						<span class="kindbox" @click.stop="azshow=!azshow">
							{{langs.cj_lxb}}
						</span>
						<img  :style="azshow?'transform: rotate(270deg)':''" src="../../assets/images/u182.png" @click.stop="azshow=!azshow" />
						<div class="select-back"  v-if="azshow" @click.stop="azshow=!azshow"></div>
						<div class="select-right into" v-if="azshow" >
							<div class="select_title">{{langs.psearch_seaz}}</div>
							<div class="select_socl">
								<div class="select_item " @click.stop="goinfo('zd')" >{{langs.cj_zd}}</div>
								<div class="select_item select_item_hover" @click.stop="goinfo('lxb')">{{langs.cj_lxb}}</div>
				
							</div>
				
						</div>
					</div>
				
				</div>
				
			</div>
			<div class="d_right" v-else-if="isMobile()&&jscj=='1'">
				<div class="d_right_go" @click="gozudcj1()">
					<div v-if="langs.matchyf_hmk!=''">{{langs.matchyf_hmk}}</div>
					<div>{{langs.matchyf_zdcj}}</div>
					<!-- 逐洞成绩 -->
				</div>
			</div>
			<div class="d_right" v-else-if="isMobile()">
				<div class="d_right_go" @click="gozudcj()">
					<div v-if="langs.matchyf_hmk!=''">{{langs.matchyf_hmk}}</div>
					<div>{{langs.matchyf_zdcj}}</div>
					<!-- 逐洞成绩 -->
				</div>
			</div>
			
		</div>
		<div class="lf_match_info_box ">
			<div class="lf_group_tit_left" v-if="info.info && info.info.match_id =='32936'">
				<el-radio-group v-model="radio">
					<el-radio v-if="info.info && info.info.mt_round" v-for="i in info.info.mt_round_num"
						:label="i">{{ systemlang=='cn'?'排位赛': 'Qualifying'}}</el-radio>
				</el-radio-group>
			</div>
			<div class="lf_group_tit_left" v-else>
				<el-radio-group v-model="radio">
					<el-radio v-if="info.info && info.info.mt_round" v-for="i in info.info.mt_round_num"
						:label="i">{{langs.text_di}}{{i}}{{langs.text_lun}}</el-radio>
				</el-radio-group>
			</div>

		</div>
		
		<div id="pdf" style="cursor:default" class="lf_course_name_data_box">
			<div style="cursor:default" class="lf_tec_download">
				<div class="lf_score_tips">
					<div>{{langs.Amateur}}</div>
					<div>{{langs.StartfromBacknine}} </div>
					<div><text style="margin-right: 0.1rem;"> F </text>{{langs.Confirmed}}</div>
					
					<div><img src="../../assets/images/100.png" alt=""
							style="margin-right: 0.1rem;">{{langs.WoninPlayoff}}</div>
				</div>
				<div style="display: flex;align-items: center;">
					<div class="lf_tec_download_img_box" @click="DouwnExcel()" >
						<text>{{langs.DownloadExcel}} </text><img src="../../assets/images/Excel.png" alt="">
					</div>
					<div class="lf_tec_download_img_box" @click="Douwn()">
						<text>{{langs.DownloadPDF}} </text><img src="../../assets/images/u2818.png" alt="">
					</div>
				</div>
			</div>
			<div class="lf_course_name_data_tit">
				<div class="lf_1" style="justify-content: center;">Pos</div>
				<div class="lf_2">Player</div>
				<div class="lf_3_1">ToPar</div>
				<div class="lf_3_1" style="color: #C0153E;">R{{radio}}</div>
				<div class="lf_3">Thru</div>
				<div class="lf_4">ToPar</div>
				<div class="lf_5">Today</div>
				<div class="lf_6" v-for="r in radio">R{{r}}</div>
				<div class="lf_7">Total</div>
				<div class="lf_8">Prize{{info && info.info && info.info.match_id=='31897'?'(USD)':''}}</div>
			</div>
			<!--lf_course_name_data_con_select lf_course_name_data_con-->
			<div style="width: 100%;" v-if="info.list && info.list <=0">
						<empty></empty>			 
			</div>
			<div v-if="info.list && likepl.length > 0" class="lf_jjx">{{systemlang =='cn'?'关注的球员':'Favorites'}} {{systemlang !='cn'?'关注的球员':'Favorites'}}<!--关注的球员--></div>
			
			
			<template v-for="(b,index) in info.list">
			<div v-if="info.list"  :style="index%2==0?'background-color: rgb(255, 255, 255);':'background-color: rgb(253, 253, 253);'">

				<div v-if="likepl.indexOf(b.su_pl_id) >= 0" :class="getClass(b.su_pl_id)">
					<!-- ../../assets/images/u2834.png -->
					<div class="lf_1" @click="likes(b.su_pl_id)"><img src="../../assets/images/u2835.png"
							alt="">
							<img v-if="info.addhole&&b.rank=='1'" style="width: 0.2rem;height: 0.2rem;margin: 0;" src="../../assets/images/100.png" alt="">
							{{info.addhole&&b.rank=='1'?'':b.rank}}</div>
					<div class="lf_2 hoverUnderLine" @click="checkplid(b.su_pl_id)">
						<img style="width:0.32rem;" v-if="b.pl_country" :src="b.pl_country" alt="">
						{{b.pl_name}}
						<div v-if="b.zus&&b.zus.length>0" class="lf_club">
							<banner v-if="b.zus&&b.zus.length>0" heightNew="0.5rem" :type="2" datatype="1" :dataurl="b.zus"></banner>
						</div>
					</div>
					<div class="lf_3_1" :class="parseInt(b.topar) < 0?'lf_thru_F':''">{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}</div>
					<div class="lf_3_1" :class="parseInt(b.today) < 0?'lf_thru_F':''">{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}</div>
					<!-- lf_thru_F 红色的F -->
					<div :class="'lf_3 '+(b.ok_h=='F'?'lf_thru_F':'')">{{b.ok_h==18?'F':(b.ok_h==''&&b.roundsts==''?b.start_time:b.ok_h)}}<span style="color: #666666;">{{b.hou9=='1'&&b.roundsts==''?'*':''}}</span></div>
					<div class="lf_4" :class="parseInt(b.topar) < 0?'lf_thru_F':''">
						{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}
					</div>
					<div class="lf_5" :class="parseInt(b.today) < 0?'lf_thru_F':''">
						{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}
					</div>
					<div class="lf_6" v-for="r in radio">{{b['R'+r]}}</div>
					<div class="lf_7">{{b.total==0?'-':b.total}}</div>
					<div class="lf_8">{{b.pr_amount==0?'-':b.pr_amount}}</div>
				</div>


				<div class="tablebox"
					v-if="checkplidv ==b.su_pl_id && likepl.indexOf(b.su_pl_id) >= 0  && zctype !='zc'">
					<div class="tablehead">

						<swiper :style="radio==4?'height:5.4rem;':'height:4.6rem;'" :modules="modules" :autoplay="false" :pagination="{ clickable: true }"
							@swiper="onSwiper" @slideChange="onSlideChange" class="swiper_all">
							<swiper-slide class="swiper_item">
								<div class="pink_body">
									<div class="white_body">
										<el-row>
											<el-col :xs="24" :sm="24" :md="24">

												<div class="lf_match_info_con">
													<div class="lf_221 bold" style="height: 1rem;">
														<div>Hole</div>
														<div>Par</div>
													</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id<=9" class="lf_22 redColor ">
															<div>{{h.mh_id}}</div>
															<div>{{h.mh_par}}</div>
														</div>
													</template>
													<div class="lf_11 bold">
														<div>OUT</div>
														<div>{{jsPar('OUT')}}</div>
													</div>
												</div>
												<div class="lf_match_info_score_con" v-for="ii in radio">
													<div class="lf_221 bold">R{{ii}}</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id<=9" class="lf_22" :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''">
															{{info.cj && info.cj[checkplidv][ii] &&info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
														</div>
													</template>
													<div class="lf_11 bold">{{jsParScore(checkplidv,ii,'OUT')}}</div>
												</div>

											</el-col>

										</el-row>
									</div>

								</div>
							</swiper-slide>


							<swiper-slide class="swiper_item">
								<div class="pink_body">
									<div class="white_body">
										<el-row>
											<el-col :xs="24" :sm="24" :md="24">
												
												<div class="lf_match_info_con">
													<div class="lf_221 bold" style="height: 1rem;">
														<div>Hole</div>
														<div>Par</div>
													</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id>9" class="lf_22 redColor ">
															<div>{{h.mh_id}}</div>
															<div>{{h.mh_par}}</div>
														</div>
													</template>
													<div class="lf_11 bold">
														<div>IN</div>
														<div>{{jsPar('IN')}}</div>
													</div>
												</div>
												<div class="lf_match_info_score_con" v-for="ii in radio">
													<div class="lf_221 bold">R{{ii}}</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id>9" class="lf_22" :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''">
															{{info.cj && info.cj[checkplidv][ii] &&info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
														</div>
													</template>
													<div class="lf_11 bold">{{jsParScore(checkplidv,ii,'IN')}}</div>
												</div>

											</el-col>

										</el-row>
									</div>

								</div>
							</swiper-slide>

						</swiper>


					</div>
				</div>
				

			</div>
			<div v-if="checkplidv ==b.su_pl_id && likepl.indexOf(b.su_pl_id) >= 0  && zctype !='zc'"
					class="lf_match_info_box_con">
					<div class="pink_body">
						<div class="white_body">
							<el-row>
								<el-col v-if="OutInFlag" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div class="lf_221">Hole</div>
										<template v-for="h in info.hole">
											<div v-if="h.mh_id <= 9" class="lf_22">
												<div>{{h.mh_id}}</div>
												<div>{{h.mh_par}}</div>
											</div>
										</template>
										<div class="lf_11">
											<div>OUT</div>
											<div>{{jsPar('OUT')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img
												src="../../assets/images/r.png" alt=""></div>
									</div>
									<div class="lf_match_info_score_con" v-for="ii in radio">
										<div class="lf_221">R{{ii}}</div>
										<!-- 输 lf_e赢 lf_b 平 .lf_par -->
										<template v-for="h in info.hole">
											<div class="lf_22" :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''" v-if="h.mh_id <= 9">
												{{info.cj && info.cj[checkplidv][ii] &&info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
											</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'OUT')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
								<el-col v-if="!OutInFlag||WebModel" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div v-if="!WebModel" class="lf_221">
											<div>Hole</div>
											<div>Par</div>
										</div>
										<template v-for="h in info.hole">
											<div v-if="h.mh_id > 9" class="lf_22">
												<div>{{h.mh_id}}</div>
												<div>{{h.mh_par}}</div>
											</div>
										</template>
										<div class="lf_11">
											<div>IN</div>
											<div>{{jsPar('IN')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img
												style="transform: rotate(180deg);" src="../../assets/images/r.png"
												alt=""></div>
									</div>
									<div class="lf_match_info_score_con" v-for="ii in radio">
										<div v-if="!WebModel" class="lf_221">R{{ii}}</div>
										<template v-for="h in info.hole">
											<div class="lf_22" v-if="h.mh_id > 9" :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''">
												{{info.cj && info.cj[checkplidv][ii] && info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
											</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'IN')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</template>


			<div v-if="info.list  && likepl.length > 0" class="lf_jjx">{{systemlang =='cn'?'全部球员':'All Players'}} {{systemlang !='cn'?'全部球员':'All Players'}}<!--关注的球员--></div>
			
			<template v-for="(b,index) in info.list" :key="b.su_pl_id">
			<div v-if="info.list"   :style="index%2==0?'background-color: rgb(255, 255, 255);':'background-color: rgb(253, 253, 253);'">

				<div :class="getClass(b.su_pl_id)">
					
					<div class="lf_1" @click="likes(b.su_pl_id)">
						<img v-if="likepl.indexOf(b.su_pl_id) >= 0" src="../../assets/images/u2835.png" alt="">
						<img v-if="likepl.indexOf(b.su_pl_id) < 0" src="../../assets/images/u2834.png" alt="">
						<img v-if="info.addhole&&b.rank=='1'" style="width: 0.2rem;height: 0.2rem;margin: 0;" src="../../assets/images/100.png" alt="">
						{{info.addhole&&b.rank=='1'?'':b.rank}}
					</div>
					<div class="lf_2 hoverUnderLine" @click="checkplid(b.su_pl_id,'zc')">
						<img style="width:0.32rem;" v-if="b.pl_country" :src="b.pl_country" alt="">
						{{b.pl_name}}
						<div v-if="b.zus&&b.zus.length>0" class="lf_club">
							<banner v-if="b.zus&&b.zus.length>0" heightNew="0.5rem" :type="2" datatype="1" :dataurl="b.zus"></banner>
						</div>
					</div>
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_3_1" :class="parseInt(b.topar) < 0?'lf_thru_F':''">{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}</div>
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_3_1" :class="parseInt(b.today) < 0?'lf_thru_F':''">{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}</div>
					
					<div @click="checkplid(b.su_pl_id,'zc')" :class="'lf_3 '+(b.ok_h=='F'?'lf_thru_F':'')">{{b.ok_h==18?'F':(b.ok_h==''&&b.roundsts==''?b.start_time:b.ok_h)}}<span style="color: #666666;">{{b.hou9=='1'&&b.roundsts==''?'*':''}}</span></div>
					
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_4" :class="parseInt(b.topar) < 0?'lf_thru_F':''">
						{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}
					</div>
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_5" :class="parseInt(b.today) < 0?'lf_thru_F':''">
						{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}
					</div>
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_6" v-for="r in radio">{{b['R'+r]}}</div>
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_7">{{b.total==0?'-':b.total}}</div>
					<div @click="checkplid(b.su_pl_id,'zc')" class="lf_8">{{b.pr_amount==0?'-':b.pr_amount}}</div>
				</div>
			</div>

			
			
			<div class="tablebox"
					v-if="checkplidv ==b.su_pl_id && zctype =='zc'">
					<div class="tablehead">

						<swiper :style="radio==4?'height:5.4rem;':'height:4.6rem;'" :modules="modules" :autoplay="false" :pagination="{ clickable: true }"
							@swiper="onSwiper" @slideChange="onSlideChange" class="swiper_all">
							<swiper-slide class="swiper_item">
								
								<div id="lf_pink_body" class="pink_body">
									<div class="white_body">
										<el-row>
											<el-col :xs="24" :sm="24" :md="24">

												<div class="lf_match_info_con">
													<div class="lf_221 bold" style="height: 1rem;">
														<div>Hole</div>
														<div>Par</div>
													</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id<=9" class="lf_22 redColor ">
															<div>{{h.mh_id}}</div>
															<div>{{h.mh_par}}</div>
														</div>
													</template>
													<div class="lf_11 bold">
														<div>OUT</div>
														<div>{{jsPar('OUT')}}</div>
													</div>
												</div>
												<div class="lf_match_info_score_con" v-for="ii in radio">
													<div class="lf_221 bold">R{{ii}}</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id<=9" class="lf_22" :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''">
															{{info.cj && info.cj[checkplidv][ii] &&info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
														</div>
													</template>
													<div class="lf_11 bold">{{jsParScore(checkplidv,ii,'OUT')}}</div>
												</div>

											</el-col>

										</el-row>
									</div>

								</div>
							</swiper-slide>


							<swiper-slide class="swiper_item">
								<div class="pink_body">
									<div class="white_body">
										<el-row>
											<el-col :xs="24" :sm="24" :md="24">
												
												<div class="lf_match_info_con">
													<div class="lf_221 bold" style="height: 1rem;">
														<div>Hole</div>
														<div>Par</div>
													</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id>9" class="lf_22 redColor ">
															<div>{{h.mh_id}}</div>
															<div>{{h.mh_par}}</div>
														</div>
													</template>
													<div class="lf_11 bold">
														<div>IN</div>
														<div>{{jsPar('IN')}}</div>
													</div>
												</div>
												<div class="lf_match_info_score_con" v-for="ii in radio">
													<div class="lf_221 bold">R{{ii}}</div>
													<template v-for="h in info.hole">
														<div v-if="h.mh_id>9" class="lf_22" :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''">
															{{info.cj && info.cj[checkplidv][ii] &&info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
														</div>
													</template>
													<div class="lf_11 bold">{{jsParScore(checkplidv,ii,'IN')}}</div>
												</div>

											</el-col>

										</el-row>
									</div>

								</div>
							</swiper-slide>

						</swiper>


					</div>
				</div>

			<div v-if="checkplidv ==b.su_pl_id && zctype =='zc'" class="lf_match_info_box_con">
					<div class="pink_body">
						<div class="white_body">
							<el-row>
								<el-col v-if="OutInFlag" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div class="lf_221">
											<div>Hole</div>
											<div>Par</div>
										</div>
										<template v-for="h in info.hole">
											<div v-if="h.mh_id <= 9" class="lf_22">
												<div>{{h.mh_id}}</div>
												<div>{{h.mh_par}}</div>
											</div>
										</template>
										
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img
												src="../../assets/images/r.png" alt=""></div>
									</div>
									<div class="lf_match_info_score_con" v-for="ii in radio">
										<div class="lf_221">R{{ii}}</div>
										<!-- 输 lf_e赢 lf_b 平 .lf_par -->
										<template v-for="h in info.hole">
											<div class="lf_22"
												:class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''"
												v-if="h.mh_id <= 9">
												{{info.cj && info.cj[checkplidv][ii] &&  info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
											</div>
										</template>
										<div class="lf_223"></div>
									</div>
								</el-col>
								<el-col v-if="!OutInFlag||WebModel" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div v-if="!WebModel" class="lf_221">Hole</div>
										<div class="lf_11">
											<div>OUT</div>
											<div>{{jsPar('OUT')}}</div>
										</div>
										<template v-for="h in info.hole">
											<div v-if="h.mh_id > 9" class="lf_22">
												<div>{{h.mh_id}}</div>
												<div>{{h.mh_par}}</div>
											</div>
										</template>
										<div class="lf_11">
											<div>IN</div>
											<div>{{jsPar('IN')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img
												style="transform: rotate(180deg);" src="../../assets/images/r.png"
												alt=""></div>
									</div>
									<div class="lf_match_info_score_con" v-for="ii in radio">
										<div v-if="!WebModel" class="lf_221">R{{ii}}</div>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'OUT')}}</div>
										<template v-for="h in info.hole">
											<!-- 输 lf_e赢 lf_b 平 .lf_par -->
											<div :class="info.tp && info.tp[checkplidv] && info.tp[checkplidv][ii] && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''"
												class="lf_22" v-if="h.mh_id > 9">
												{{info.cj && info.cj[checkplidv][ii] && info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}
											</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'IN')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
			</div>

			<div v-if="(index+1)%20==0&& !WebModel" class="banner" v-show="bannershow && bannershow['RANK_CENTER'] == '1'">
				<banner  :type="2" abs="RANK_CENTER"></banner>
			</div>
					
			<el-col :xs="24" :sm="24" :md="24" >

				<div class="lf_jjx"
					v-if="(info.nextjiji == '0' && info.check_ok == 0 && info.jinji>0 && b.su_rank == info.jinji && !info.list[index+1]) || (info.nextjiji == '0' && info.check_ok == 0 && info.jinji>0 && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].su_rank != b.su_rank)"
					style="width: 100;text-align: center;background-color: #C0153E;">
					{{systemlang == 'cn'?'预测晋级线':'Projected Cut Line'}}
				</div>
				<div class="lf_jjx"
					v-if="(info.nextjiji == '1' && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].mp_sts != '') || (info.check_ok == 1 && info.jinji>0 && b.su_rank == info.jinji && !info.list[index+1]) || (info.check_ok == 1 && info.jinji>0 && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].su_rank != b.su_rank)"
					style="width: 100;text-align: center;background-color: #C0153E;">
					{{systemlang == 'cn'?'晋级线':'Cut Line'}}
				</div>

			</el-col>
			<!--放置手机端-->
			
			<div v-if="(index+1)%10==0 && !WebModel" style="margin-top: 0.2rem;" class="lf_course_name_data_tit">
					<div class="lf_1" style="justify-content: center;">Pos</div>
					<div class="lf_2">Player</div>
					<div class="lf_3_1">ToPar</div>
					<div class="lf_3_1" style="color: #C0153E;">R{{radio}}</div>
					<div class="lf_3">Thru</div>
					<div class="lf_4">ToPar</div>
					<div class="lf_5">Today</div>
					<div class="lf_6" v-for="r in radio">R{{r}}</div>
					<div class="lf_7">Total</div>
					<div class="lf_8">Prize{{info && info.info && info.info.match_id=='31897'?'(USD)':''}}</div>
					</div>


			
		</template>
	</div>

	</div>
	<Tips @aliclickshowhide="aliclickshowhide" :show="show"></Tips>
	<!-- <Ad></Ad> -->
</template>

<script>
import banner from "../banner.vue";
	import {
		result
	} from 'lodash';
	import html2Canvas from '../../plugs/htmlToPDF';
	import Tips from '../Tips.vue';
	import {
		getSystemLang,
		getLangAll
	} from '../../lang';
	import axios from '../../axios/request';
	import Ad1 from "../Ad1.vue";

	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	import empty from "../empty.vue";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				info: {},
				pmlist: [],
				plname: '',
				checkplidv: '',
				radio: '',
				cradio: '',
				value: '',
				cj: 'cj',
				likepl: [],
				zctype: 'zc', //点击区域
				tmflag: '0',
				OutInFlag: true,
				WebModel: true,
				WebModelfirst: true,
				azshow:false,
				bannershow:[],
				lf_pink_body_H:400,

			}
		},
		name: 'Player',
		props: {
			msg: String,
			mt_id: String,
			flag: String,
			round_id: String,
			jscj:String
		},
		components: {
			Ad1,
			Tips,
			Swiper,
			SwiperSlide,
			banner,
			empty
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		
		created() {
			document.body.addEventListener('click', (e) => {
				// console.log(e.target)
				this.azshow = false;
			});
			

			this.getData(()=>{
				
				if(this.info.info.ColumnStart && this.info.info.ColumnEnd)
				{
					let  timestamp = new Date().getTime();
					if(timestamp >= this.info.info.ColumnStart*1000 && timestamp <= this.info.info.ColumnEnd*1000)
					{
						setInterval(() => {
							this.getData1();
						}, 600000);
					}
				}
			});
			//  this.$cookies.remove('likepl')
			console.log('likpl', this.$cookies.get(this.mt_id+'likepl'));
			if (this.$cookies.isKey(this.mt_id+'likepl')) {
				console.log('likpl', this.$cookies.get(this.mt_id+'likepl'));
				this.likepl = JSON.parse(this.$cookies.get(this.mt_id+'likepl'));
			}
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.cj = this.flag;
			if (this.round_id > 0) {
				this.radio = this.round_id;
			}
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if (deviceWidth >= 770) {
				this.WebModel = true;
				this.WebModelfirst = true;
			} else {
				this.WebModel = false;
				this.WebModelfirst = false;
			}
			console.log('round', 'a:' + this.round_id, 'b:' + this.radio);
		},
		mounted() {
			window.addEventListener('resize', () => {
				var deviceWidth = document.documentElement.clientWidth || window.innerWidth
				if (deviceWidth >= 770) {
					this.WebModel = true;
					
				} else {
					
					this.WebModel = false;
				}
			})

			
		},
		watch: {
			radio: function(newValue, oldValue) {
				console.log(newValue, oldValue);
				if (newValue != oldValue) {
					this.radio = newValue;
					this.getData1();
				}

			},
			cj: function(newValue, oldValue) {
				console.log(newValue, oldValue);
				if (newValue != oldValue) {
					console.log('show', this.radio);
					if (newValue == 'cj') {
						this.$emit('goInfo', 'cj', '', this.raido);
					} else {
						this.$emit('goInfo', 'cj', 'tm', this.raido);
					}


				}

			},
		},
		methods: {
			isMobile(){
				const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  				return reg.test(navigator.userAgent);
			},
			setbannershow(abs, isshow) {
			// console.log(abs,isshow,'ssssssssssssssss');
			this.bannershow[abs] = isshow;
			},
			goinfo(flag){
				if(flag == 'zd')
				{
					//zdcj:逐洞成绩 lxb:领先榜
					this.$emit('goInfo', 'cj', 'zdcj', this.raido);
				}
				else
				{
					this.$emit('goInfo', 'cj', 'lxb', this.raido);
				}
			},
			gozudcj(){
				let routeUrl = this.$router.resolve({
						path: "/Match",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: 'cj',
							bFlag: 'zdcj'
						}
					});
					// console.log(routeUrl.href);
					window.open(routeUrl.href, '_self');
					return false;
			},
			gozudcj1(){
				let routeUrl = this.$router.resolve({
						path: "/Matchnow",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: 'cj',
							bFlag: 'zdcjzd'
						}
					});
					// console.log(routeUrl.href);
					window.open(routeUrl.href, '_self');
					return false;
			},
			getData(callback) {
				//获取banner
				axios('matchs/match/matchtj.json', {
						otp: 'playerScore',
						mt_id: this.mt_id,
						round_id: this.radio
					})
					.then((res) => {
						let data = res.data.data;
						this.info = data;
						this.tmflag = data.tmflag;
						if (this.radio < 1) {
							this.radio = parseInt(data.info.mt_round);
						}

						this.cradio = parseInt(data.info.mt_round_num);
						callback && callback();
						// console.log(this.radio,'radio');
						// setTimeout(()=>{
						// 	var div = document.getElementById('lf_pink_body');
						// 	this.lf_pink_body_H = div.getBoundingClientRect().height;
						// 	console.log('this.lf_pink_body_H',this.lf_pink_body_H)
						// },100);
					});
			},
			checkplid(pl_id, stype) {
				if (stype == 'zc') {
					this.zctype = stype;
				} else {
					this.zctype = stype;
				}

				if (this.checkplidv != pl_id) {
					this.checkplidv = pl_id;
				} else {
					this.checkplidv = '';
				}

			},
			getClass(pl_id) {
				//lf_course_name_data_con_select lf_course_name_data_con
				if (this.checkplidv == pl_id) {
					return 'lf_course_name_data_con_select lf_course_name_data_con';
				}
				return 'lf_course_name_data_con';

			},
			getData1() {
				//获取banner
				axios('matchs/match/matchtj.json', {
						otp: 'playerScore',
						mt_id: this.mt_id,
						round_id: this.radio
					})
					.then((res) => {
						let data = res.data.data;
						this.info = data;
						console.log(this.info, data);
						this.tmflag = data.tmflag;

						// console.log(this.radio,'radio');

					});
			},
			likes(pl_id) {
				console.log('1', pl_id);
				console.log(JSON.stringify(this.likepl));

				if (this.likepl.indexOf(pl_id) == -1) {
					this.likepl.push(pl_id);
					console.log('2', JSON.stringify(this.likepl));
				} else {

					this.likepl.splice(this.likepl.indexOf(pl_id), 1);
					console.log('3', pl_id);
					console.log(JSON.stringify(this.likepl));
				}
				console.log(JSON.stringify(this.likepl));
				this.$cookies.set(this.mt_id+'likepl', JSON.stringify(this.likepl), -1);
			},
			jsPar($str) {
				if (!(this.info && this.info.hole)) {
					return '-';
				}
				var rsult = 0;
				if ($str == 'OUT') {
					let hole = this.info.hole;
					for (let i in hole) {
						if (hole[i].mh_par && hole[i].mh_id <= 9) {
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if ($str == 'IN') {
					let hole = this.info.hole;
					for (let i in hole) {
						if (hole[i].mh_par && hole[i].mh_id > 9) {
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if (rsult == 0) {
					return '--';
				} else {
					return rsult;
				}

			},

			jsParScore($pl, $rid, $str) {
				if (!(this.info && this.info.cj && this.info.cj[$pl] && this.info.cj[$pl][$rid])) {
					return '-';
				}

				var rsult = 0;
				if ($str == 'OUT') {
					let hole = this.info.cj[$pl][$rid];
					for (let i in hole) {
						if (hole[i] && i <= 9) {
							rsult += parseInt(hole[i]);
						}
					}
				}

				if ($str == 'IN') {
					let hole = this.info.cj[$pl][$rid];
					for (let i in hole) {
						if (hole[i] && i > 9) {
							rsult += parseInt(hole[i]);
						}
					}
				}

				if (rsult == 0) {
					return '--';
				} else {
					return rsult;
				}
			},
			aliclickshowhide($s) {
				if ($s == '1') {
					this.show = '1';
				} else {
					this.show = '0';
				}
			},
			is_weixn() {

				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {

					return true;

				}
				return false;

			},
			DouwnExcel() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				// /public/index.php/core/en-us/matchs/match/matchtj.json?otp=playerScoreExport&mt_id=7964&round_id=2
				let url = 'http://www.clpga.org/public/index.php/core/' + lang +
					'/matchs/match/matchtj.json?otp=playerScoreExport&mt_id=' + this.info.info.ID + '&round_id=' + this
					.radio;

				// let url = domain+'/index.php/Home/Match/ExpResult/year/'+this.info.info.year+'/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio+'/mt_round/'+this.info.info.mt_round_num+'';
				window.open(url, '_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			Douwn() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				// let cuurl = domain+'/index.php/Home/Match/expPersonalAllInfoPdf/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio;
				let url = domain + '/index.php/Home/Match/ExpResult/year/' + this.info.info.year + '/match_id/' + this.info
					.info.ID + '/lang/' + lang + '/rid/' + this.radio + '/mt_round/' + this.info.info.mt_round_num + '';
				window.open(url, '_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			OutInChange(a) {
				if (a) {
					this.OutInFlag = false
				} else {
					this.OutInFlag = true
				}
				console.log(this.OutInFlag)
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.oddColor {
		overflow: hidden;

	}
	.all_body{
		position: relative;
	}
	.tablebox {
		display: none;
	}

	.lf_jjx {
		height: 0.5rem;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: center;
		font-size: 0.24rem;
		width: 100%;
		background-color: #C0153E;
	}

	.oddColor:nth-child(odd) .lf_course_name_data_con {
		background: rgba(242, 242, 242, 1);
	}
	.oddColor:nth-child(odd) .lf_course_name_data_con_select{
		background-color: #FFF1F2!important;
	} 
	.oddColor:nth-child(odd) .lf_course_name_data_con_select div{
		background-color: #FFF1F2!important;
	} 
	.oddColor:hover .lf_course_name_data_con,.oddColor:hover .lf_course_name_data_con div{
		background-color: #FFF1F2;
	}
	.oddColor:nth-child(odd) .banner,.oddColor:nth-child(odd) .banner div{background-color: unset!important;}
	.oddColor:nth-child(odd) .lf_course_name_data_tit{background-color: #FFF1F2;}
	.oddColor:nth-child(odd) .lf_course_name_data_tit div{
		background-color: #FFF1F2;
	}
	.top_to {
		margin-top: 0.2rem;
	}

	.lf_group_tit_left {
		margin-top: 0.2rem;
	}

	.d_right {
		float: right;
		margin-top: 0.2rem;
	}

	.d_right_text {
		float: left;
		color: #C0153E;
		margin-right: 0.2rem;
		font-size: 0.24rem;
		height: 0.3rem;
		line-height: 0.3rem;
		;
	}

	.d_right img {
		width: 0.3rem;
		height: 0.3rem;
		float: right;
		transform: rotate(90deg);

	}


	.lf_match_info_box_r {
		display: flex;
		justify-content: flex-end;
	}

	.lf_course_bogo {
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}

	.lf_course_bogo_des {
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}

	.lf_course_name_data_box {
		background-color: #FAFAFA;
		margin-top: 0.3rem;
	}

	.lf_score_tips {
		display: flex;
		align-items: center;
		color: #666666;
		font-size: 0.22rem;
	}

	.lf_score_tips div {
		margin: 0 0.2rem;
	}

	.lf_score_tips div text {
		color: #C0153E;
	}

	.lf_score_tips div img {
		width: 0.2rem !important;
		height: 0.2rem !important;
	}

	.lf_tec_download {
		width: 98%;
		color: #C0153E;
		font-size: 0.22rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		cursor: pointer;
	}
	.lf_3_1{
		display: none !important;
	}
	.lf_tec_download div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_tec_download img {
		width: 0.24rem;
		height: 0.24rem;
		margin-left: 0.1rem;
	}

	.lf_course_name_data_tit {
		width: 98%;
		border-bottom: 1px solid #efefef;
		color: #666666;
		font-size: 0.24rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	.lf_course_name_data_con {
		width: 98%;
		color: #000;
		font-size: 0.28rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;

	}

	.lf_course_name_data_con_select {
		background-color: #FFF1F2;
	}

	.lf_course_name_data_tit div,
	.lf_course_name_data_con div {
		width: 9.8%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_thru_F {
		color: #C0153E;
	}

	.lf_2 {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row !important;
	}

	.lf_2 img {
		margin-right: 0.2rem;
	}

	.lf_course_name_data_con .lf_2 {
		justify-content: flex-start;
		cursor: pointer;
	}

	.lf_course_name_data_tit .lf_1,
	.lf_course_name_data_con .lf_1 {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 12%;
	}

	.lf_1 img {
		width: 0.2rem;
		height: 0.2rem;
		margin: 0 0.2rem;
		cursor: pointer;
	}

	.lf_2 .lf_country {
		width: 0.32rem;
		height: 0.26rem;
		margin-right: 0.1rem;
	}

	.lf_2 .lf_club {
		width: 0.73rem;
		height: 0.25rem;
		margin-left: 0.1rem;
	}

	.lf_6 {
		width: 0.6rem !important;
	}

	.lf_match_info_box_con {
		margin: 0 1%;
		background-color: #fff;
		overflow: hidden;
		padding-bottom: 0.24rem;
		display: block;
	}

	.pink_body {
		width: 17.12rem;
		background-color: #FFF1F2;
		border-radius: 8px;
		margin: 0 auto;
		margin-top: 0.24rem;
		overflow: hidden;
		padding-bottom: 0.2rem;
	}

	.white_body {
		width: 16.72rem;
		background-color: #fff;
		border-radius: 8px;
		margin: 0 auto;
		margin-top: 0.2rem;
	}

	/* 	<div class="pink_body">
		<div class="white_body"> */

	.lf_match_info_con {
		display: flex;
		box-sizing: border-box;
		border-bottom: 1px solid #EFEFEF;
	}

	.lf_match_info_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		color: #333;
	}

	.lf_match_info_con .lf_21,
	.lf_match_info_con .lf_22 {
		width: 0.73rem;
		height: 1rem;
		color: #C0153E;
	}

	.lf_match_info_con .lf_22 {
		display: flex;
		flex-direction: column;
	}

	.lf_match_info_con .lf_22 div,
	.lf_match_info_con .lf_11 div {
		height: 0.5rem;
		width: 100%;
	}
	.lf_match_info_con .lf_22 div:nth-of-type(1){
		color: #C0153E;
	}
	.lf_match_info_con .lf_11 {
		width: 1.1rem;
		color: #C0153E;
		flex-direction: column;
		height: 1rem;
		
	}
	.lf_match_info_con .lf_11 div{
		font-weight: bold;
	}
	.lf_match_info_con .lf_11 div:nth-of-type(1),.lf_match_info_con .lf_221 div:nth-of-type(1){
		border-right: 1px solid #F7F7F7;
	}
	.lf_22 div:nth-of-type(1){
		border-right: 1px solid #F7F7F7;
	}
	.lf_match_info_con .lf_11 div:nth-of-type(2),.lf_match_info_con .lf_221 div:nth-of-type(2){
		background-color: #F7F7F7;
		color: #666!important;
		font-weight: bold;
	}
	.lf_22 div:nth-of-type(2){
		background-color: #F7F7F7;
		color: #666;
	}
	.lf_match_info_score_con {
		display: flex;
		box-sizing: border-box;
		border-bottom: 1px solid #F7F7F7;
	}
	.lf_match_info_score_con div{
		border-right: 1px solid #F7F7F7;
	}
	.lf_match_info_score_con .lf_221 {
		color: #C0153E;
		font-weight: bold;
		flex-grow: 1;
		font-size: 0.24rem;
	}

	.lf_match_info_score_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.73rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		color: #333;
	}

	.lf_match_info_score_con .lf_21,
	.lf_match_info_score_con .lf_22 {
		height: 0.8rem;
	}

	.lf_match_info_score_con .lf_22 {
		display: flex;
		flex-direction: column;
	}


	.lf_match_info_score_con .lf_11 {
		width: 1.1rem;
		height: 0.8rem;
		color: #333;
		font-weight: bold;
	}

	.lf_match_info_score_con .lf_b {
		color: #B6081D;
	}

	.lf_match_info_score_con .lf_par {
		color: #333;
	}

	.lf_match_info_score_con .lf_e {
		color: #2C2AE3;
	}

	.lf_match_info_con .lf_11 div {
		color: #C0153E !important;
	}

	.lf_match_info_con .lf_221 {
		color: #C0153E !important;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex-grow: 1;
		font-size: 0.24rem;
	}
	.lf_match_info_con .lf_221 div{
		color: #C0153E !important;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 0.5rem;
		width: 100%;
	}

	.lf_Playoff_box {
		background-color: #FAFAFA;
	}

	.lf_Playoff_tit {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #EFEFEF;
		color: #333;
		font-size: 0.24rem;
		width: 98%;
		margin: 0 auto;
		height: 0.5rem;
	}

	.lf_Playoff_con {

		font-size: 0.28rem;
		display: flex;
		align-items: center;
		height: 0.7rem;
		width: 98%;
		margin: 0 auto;
	}

	.lf_off_1 {
		width: 0.96rem;
		margin-right: 0.3rem;
		font-size: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.31rem;
	}

	.lf_off_1_win {
		background: inherit;
		background-color: rgba(182, 8, 29, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #FFFFFF;

	}

	.lf_off_2 {
		display: flex;
		width: 3.15rem;
	
		align-items: center;
	}
	.lf_Playoff_tit .lf_off_2 {
		color: #666;
	}
	.lf_off_2 img {
		width: 0.4rem;
		margin-right: 0.15rem;
	
	}

	.lf_off_3 {
		display: flex;
		width: 2rem;
		align-items: center;
		justify-content: center;
		color: #000;
	}
	.lf_Playoff_tit .lf_off_3{
		color: #666;
	}
	.lf_223 {
		display: none !important;
	}

	.lf_jjx {
		height: 0.5rem;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: center;
	}

	.lf_e {
		background-color: #FFF692;
		color: #C0153E !important;
	}

	.lf_b {
		color: #C0153E !important;
	}

	.lf_bg {
		color: #2C2AE3 !important;
	}

	.lf_dbg {
		color: #fff !important;
		background-color: #2C2AE3;
	}
	.lf_tec_download_img_box{
		margin-left: 0.4rem;
	}
	.lf_tec_download_img_box text{
		font-size: 0.2rem !important;
	}
	.lf_tec_download_img_box img{
		width: 0.32rem;
		height: 0.32rem;
	}
	.banner {
		width: 18.5rem;
		height: 1.85rem;
		overflow: hidden;
	}
	
	.banner img {
		width: 100%;
		height: 1.85rem;
	}
	@media screen and (max-width: 768px) {
		.oddColor .lf_course_name_data_tit{margin-top: 0.2rem;}
		.tablebox {
			display: block;
		}
		.lf_5{
			display: none!important;
		}
		.lf_match_info_box_con {
			display: none;
		}

		.lf_jjx {
			display: block;
			width: 100%;
			color: #fff;
			background-color: #C0153E;
			text-align: center;
			height: 0.5rem;
			line-height: 0.5rem;
			font-size: 0.24rem;
			font-weight: bold;
		}

		.lf_course_name_data_tit {
			background-color: #FFF1F2;
		}

		.lf_course_name_data_tit .lf_6,
		.lf_course_name_data_con .lf_6,
		.lf_course_name_data_tit .lf_8,
		.lf_course_name_data_con .lf_8 {
			display: none !important;
		}

		.lf_course_name_data_tit div,
		.lf_course_name_data_con div {
			width: 14% !important;
		}

		/* .lf_1.div{
			width: 17%!important;
		} */
		.lf_1 img {
			margin: 0 0.1rem;
		}

		.lf_tec_download,
		.lf_course_name_data_tit {
			height: 0.7rem;
		}

		.lf_tec_download {
			justify-content: space-between;
			
		}
		.lf_3_1{
			display: flex!important;
			background-color: unset!important;
		}
		.lf_score_tips div:nth-of-type(n+4) {
			display: none !important;
		}

		.lf_tec_download_img_box text {
			display: none;
		}
		.d_right_go{
			width: 1.2rem;
			height: 0.6rem;
			background: inherit;
			background-color: rgba(192, 21, 62, 0);
			box-sizing: border-box;
			border-width: 1px;
			border-style: solid;
			border-color: rgba(192, 21, 62, 1);
			border-radius: 0.05rem;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
			font-size: 0.24rem;
			color: #C0153E;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			line-height: 0.24rem;
		}
		.d_right_go div{
			display: flex;
			align-items: center;
			height: fit-content;
			height: 0.24rem;
		}
		.lf_223 {
			display: flex !important;
			width: 0.5rem;
		}

		.lf_223 img {
			height: 0.5rem;
			width: 0.4rem;
		}

		.lf_jjx {
			height: 0.5rem;
		}

		.d_title,
		.d_title span {
			font-size: 0.26rem;
		}

		.lf_course_name_data_con {
			font-size: 0.26rem;
			height: 0.7rem;
		}

		.lf_e {
			background-color: #FFF692;
			color: #C0153E !important;
		}

		.lf_b {
			color: #C0153E !important;
		}

		.lf_bg {
			color: #2C2AE3 !important;
		}

		.lf_dbg {
			color: #fff !important;
			background-color: #2C2AE3;
		}

		.lf_tec_download_img_box {
			display: none !important;
		}

		.lf_4,.lf_5,.lf_7{
			display: none!important;
		}
		.oddColor:hover .lf_course_name_data_con,.oddColor:hover .lf_course_name_data_con div{
			background-color: #fafafa;
		}
		.oddColor:nth-child(odd):hover .lf_course_name_data_con,.oddColor:nth-child(odd):hover .lf_course_name_data_con div{
			background-color: #f2f2f2;
		}
		.oddColor:nth-child(odd) .lf_course_name_data_con_select{
			background-color: #FFF1F2!important;
		} 
		.oddColor:nth-child(odd) .lf_course_name_data_con_select div{
			background-color: #FFF1F2!important;
		} 
		.oddColor .lf_course_name_data_con_select,.oddColor .lf_course_name_data_con_select div{
			background-color: #FFF1F2!important;
		} 


		.tablebox {
			width: 7.5rem;
			overflow: hidden;
			background-color: #FAFAFA;
			margin-top: 0.2rem;
			padding-bottom: 0.4rem;
		}

		.tablehead {
			width: 7.1rem;
			margin-left: 0.2rem;
			overflow: hidden;

			background-color: #fff;
		}

		.redColor {
			color: #C0153E !important;
		}

		.blueColor {
			color: #2C2AE3 !important;
		}

		.pinkColor {
			background-color: #FFF1F2 !important;

		}


		.swiper_all {
			width: 100%;
			height: 5.4rem;


		}

		.swiper_item {
			width: 100%;
			height: auto;
		}

		.pink_body {
			width: 6.8rem;

			background-color: #FFF1F2;
			border-radius: 0.08rem;
			margin: 0 auto;
			margin-top: 0.24rem;
			overflow: hidden;
			padding-bottom: 0.2rem;
		}

		.white_body {
			width: 6.4rem;
			background-color: #fff;
			border-radius: 0.08rem;
			margin: 0 auto;
			margin-top: 0.2rem;

		}







		.lf_match_info_con {
			display: flex;
			box-sizing: border-box;
			border-bottom: 0.01rem solid #EFEFEF;
		}


		.lf_match_info_con div {
			display: flex;
			align-items: center;
			justify-content: center;

			font-family: '微软雅黑';

			font-style: normal;
			font-size: 0.24rem;
			color: #333;
			height: 0.5rem;
		}


		.lf_match_info_con .lf_221 {
			color: #C0153E !important;

			width: 0.9rem;
		}


		.lf_match_info_con .lf_21,
		.lf_match_info_con .lf_22 {
			width: 0.5rem;

			color: #C0153E;
			flex-grow: 1;
			flex-shrink: 0;
		}

		.lf_match_info_con .lf_22 {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
			width: 0.5rem;
		}
		

		.lf_match_info_score_con {
			display: flex;
			box-sizing: border-box;
		}


		.lf_match_info_score_con div {
			display: flex;
			align-items: center;
			justify-content: center;

			font-family: '微软雅黑';

			font-style: normal;
			font-size: 0.28rem;
			color: #333;
			height: 0.8rem;
		}

		.lf_match_info_score_con .lf_221 {
			color: #C0153E;
			width: 0.9rem;

		}


		.lf_match_info_score_con .lf_21,
		.lf_match_info_score_con .lf_22 {
			width: 0.5rem;
			height: 0.8rem;
			flex-grow: 1;
			flex-shrink: 0;
		}

		.tablehead {

			// 指示灯高度
			:deep(.el-carousel .el-carousel__indicators--horizontal) {
				height: 0.5rem;

			}

			// 指示灯未选中的样式
			:deep(.el-carousel__indicator--horizontal .el-carousel__button) {
				width: 0.14rem;
				height: 0.14rem;
				border-radius: 0.14rem;
				background: #fff;
				border: rgba(153, 153, 153) 0.01rem solid;
			}

			// 指示灯选中的样式
			:deep(.el-carousel__indicator--horizontal.is-active .el-carousel__button) {
				width: 0.14rem;
				width: 0.14rem;
				width: 0.14rem;
				background: #C0153E;
				border: #C0153E 0.01rem solid;
			}
		}
		.banner{
			width: 100%;
			height: 0.75rem;
			overflow: hidden;
			margin: 0.2rem auto 0;
		}
		.lf_2 .lf_club,.lf_2 .lf_club div{
			width: .75rem!important;
			height: 0.25rem;
		}
		.lf_2{
			overflow: hidden;
		}
		.lf_off_3{
			width: 0.8rem;
		}
	}
</style>
<style>
	.swiper .swiper-slide {
		height: auto !important;
		width: 100% !important;
	
	}
	
	.swiper .swiper-slide img {
		height: auto !important;
		width: 100% !important;
	}
	
	.swiper-pagination {
		text-align: left;
		bottom: 1.5rem !important;
		padding-left: 0.5rem;
	}
	
	.swiper-pagination-bullet {
		background-color: #fff;
		border: #DBDBDB 0.01rem solid;
		opacity: 1;
		width: 0.15rem;
		height: 0.15rem;
		margin-right: 0.02rem !important;
	}
	
	
	@media screen and (max-width: 768px) {
			.swiper-pagination {
					
				bottom: 0.2rem !important;
				padding-left: 0;
			}
			.lf_match_info_box_s{
				margin: 0.2rem 0.2rem;
			}
			
	}
</style>