<template>	
	<el-row  type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div v-if="info.addhole" class="lf_match_info_box">
				<div class="d_title">加洞赛 | <span>Playoff</span></div>
			</div>
			<div v-if="info.addhole" class="lf_Playoff_box">
				<div class="lf_Playoff_tit">
					<div class="lf_off_1"></div>
					<div class="lf_off_2">Player</div>
					<div class="lf_off_3" v-if="info.addhole" v-for="b in info.addhole.round"><text>#{{b}} </text> H{{info.addhole&&info.addhole.roundarr[b].hole?info.addhole.roundarr[b].hole:'-'}} Par{{info.addhole&&info.addhole.roundarr[b].par?info.addhole.roundarr[b].par:'-'}}</div>
				</div>
				<div v-for="b in info.addhole.list" class="lf_Playoff_con">
					<div class="lf_off_1 " :class="b.win =='1'?'lf_off_1_win':''">{{b.win =='1'?'WIN':'ELM'}}</div>
					<div class="lf_off_2"><img v-if="b.pl_country" :src="b.pl_country" alt="">{{b.pl_name}}</div>
					<div class="lf_off_3" v-for="s in b.hole">{{s}}</div>
				</div>
				
			</div>
			<div  class="lf_match_info_box">
				<div class="d_title">球员成绩表 | <span>Leaderboard</span></div>
				
			</div>
			<div class="lf_match_info_box">
				<div class="lf_group_tit_left">
					<el-radio-group v-model="radio">
						<el-radio v-if="info.info && info.info.mt_round" v-for="i in info.info.mt_round_num" :label="i">{{langs.text_di}}{{i}}{{langs.text_lun}}</el-radio>
					</el-radio-group>
				</div>
				
			</div>
			<div id="pdf" class="lf_course_name_data_box">
				<div class="lf_tec_download">
					<div class="lf_score_tips">
						<div>{{langs.Amateur}}</div>
						<div>{{langs.StartfromBacknine}}  </div>
						<div><text> F </text>{{langs.Confirmed}}</div>
						<div> F {{langs.Unconfirmed}} </div>
						<div><img src="../../assets/images/100.png" alt="">{{langs.WoninPlayoff}}</div>
					</div>
					<!-- <div class="lf_tec_download_img_box" @click="Douwn()">
						<text>{{langs.DownloadPDF}} </text><img src="../../assets/images/u2818.png" alt="">
					</div> -->
				</div>
				<div class="lf_course_name_data_tit">
					<div class="lf_1" style="justify-content: center;">Pos</div>
					<div class="lf_2">Team</div>
					<div class="lf_3">Thru</div>
					<div class="lf_4">ToPar</div>
					<div class="lf_5">Today</div>
					<div class="lf_6" v-for="r in radio">R{{r}}</div>
					<div class="lf_7">Total</div>
				</div>
				<!--lf_course_name_data_con_select lf_course_name_data_con v-if="likepl.indexOf(b.su_pl_id) >= 0"-->
				<template v-if="info.tmscore" v-for="(bt,indext) in info.tmscore" :key="bt.id">
					<div v-if="(likepl.indexOf(bt.id) >= 0)" class="lf_team_box lf_course_name_data_con" :class="showgroup == bt.id?'lf_course_name_data_con_select':''" >
						
						<div class="lf_1" @click="likes(bt.id)">
							<img v-if="likepl.indexOf(bt.id) >= 0" src="../../assets/images/u2835.png" alt="">
							<img v-if="likepl.indexOf(bt.id) < 0" src="../../assets/images/u2834.png" alt="">
						
							{{bt.rank}}</div>
						<div class="lf_2" @click="showgroupS(bt.id)">
							{{bt.team_name}}
						</div>
						
						<div :class="'lf_3 '+(bt.ok_h=='F'?'lf_thru_F':'')">-</div>
						<div class="lf_4" :class="parseInt(bt.topar) < 0?'lf_thru_F':''">{{bt.topar > 0?'+':''}}{{bt.topar == '0'?'E':bt.topar}}</div>
						<div class="lf_5" :class="parseInt(bt.today) < 0?'lf_thru_F':''">{{bt.today > 0?'+':''}}{{bt.today == '0'?'E':bt.today}}</div>
						<div class="lf_6" v-for="r in radio">{{bt['R'+r]}}</div>
						<div class="lf_7">{{bt.total==0?'-':bt.total}}</div>
					</div>
					<template v-if="(bt.pl_id && likepl.indexOf(bt.id) >= 0 )" v-for="(b,index) in bt.pl_id" :key="b.su_pl_id">
						<div :class="getClass(b.su_pl_id)" >
							
							<div class="lf_1"></div>
							<div class="lf_2" @click="checkplid(b.su_pl_id,'zc')">
								<img style="width:20px;" v-if="b.pl_country" :src="b.pl_country" alt="">
								{{b.pl_name}}
								<img v-if="b.mp_logo" class="lf_club" :src="b.mp_logo" alt="">
							</div>
							
							<div :class="'lf_3 '+(b.ok_h=='F'?'lf_thru_F':'')">{{b.ok_h==18?'F':b.ok_h}}</div>
							<div class="lf_4" :class="parseInt(b.topar) < 0?'lf_thru_F':''">{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}</div>
							<div class="lf_5" :class="parseInt(b.today) < 0?'lf_thru_F':''">{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}</div>
							<div class="lf_6" v-for="r in radio">{{b['R'+r]}}</div>
							<div class="lf_7">{{b.total==0?'-':b.total}}</div>
						</div>
						<div v-if="checkplidv ==b.su_pl_id && zctype =='zc'" class="lf_match_info_box_con">
							<el-row>
								<el-col v-if="OutInFlag" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div class="lf_221">Hole</div>
										<template v-for="h in info.hole">
										<div v-if="h.mh_id <= 9"  class="lf_22">
											<div>{{h.mh_id}}</div>
											<div>{{h.mh_par}}</div>
										</div>
										</template>
										<div class="lf_11">
											<div>OUT</div>
											<div>{{jsPar('OUT')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img src="../../assets/images/r.png" alt=""></div>
									</div>
									<div class="lf_match_info_score_con" v-for="ii in radio">
										<div class="lf_221">R{{ii}}</div>
										<!-- 输 lf_e赢 lf_b 平 .lf_par -->
										<template v-for="h in info.hole">
										<div class="lf_22" :class="info.tp && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''" v-if="h.mh_id <= 9">{{info.cj && info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'OUT')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
								<el-col v-if="!OutInFlag||WebModel" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										
										<div v-if="!WebModel" class="lf_221">Hole</div><template v-for="h in info.hole">
										<div v-if="h.mh_id > 9"  class="lf_22">
											<div>{{h.mh_id}}</div>
											<div>{{h.mh_par}}</div>
										</div>
										</template>
										<div class="lf_11">
											<div>IN</div>
											<div>{{jsPar('IN')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img style="transform: rotate(180deg);" src="../../assets/images/r.png" alt=""></div>
									</div>
									<div class="lf_match_info_score_con"  v-for="ii in radio">
										<div v-if="!WebModel" class="lf_221">R{{ii}}</div>
										<template v-for="h in info.hole">
											<!-- 输 lf_e赢 lf_b 平 .lf_par -->
										<div :class="info.tp && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''" class="lf_22" v-if="h.mh_id > 9">{{info.cj && info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'IN')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
							</el-row>
						</div>
					</template>
					
				</template>
				<template v-if="info.tmscore" v-for="(bt,indext) in info.tmscore" :key="bt.id">
					<div class="lf_team_box lf_course_name_data_con" :class="showgroup == bt.id?'lf_course_name_data_con_select':''">
						
						<div class="lf_1" @click="likes(bt.id)">
							<img v-if="likepl.indexOf(bt.id) >= 0" src="../../assets/images/u2835.png" alt="">
							<img v-if="likepl.indexOf(bt.id) < 0" src="../../assets/images/u2834.png" alt="">
						
							{{bt.rank}}</div>
						<div class="lf_2" @click="showgroupS(bt.id)">
							{{bt.team_name}}
						</div>
						
						<div :class="'lf_3 '+(bt.ok_h=='F'?'lf_thru_F':'')">-</div>
						<div class="lf_4" :class="parseInt(bt.topar) < 0?'lf_thru_F':''">{{bt.topar > 0?'+':''}}{{bt.topar == '0'?'E':bt.topar}}</div>
						<div class="lf_5" :class="parseInt(bt.today) < 0?'lf_thru_F':''">{{bt.today > 0?'+':''}}{{bt.today == '0'?'E':bt.today}}</div>
						<div class="lf_6" v-for="r in radio">{{bt['R'+r]}}</div>
						<div class="lf_7">{{bt.total==0?'-':bt.total}}</div>
					</div>
					<template v-if="(bt.pl_id )" v-for="(b,index) in bt.pl_id" :key="b.su_pl_id">
						<div :class="getClass(b.su_pl_id)" >
							
							<div class="lf_1"></div>
							<div class="lf_2" @click="checkplid(b.su_pl_id,'zc')">
								<img style="width:20px;" v-if="b.pl_country" :src="b.pl_country" alt="">
								{{b.pl_name}}
								<img v-if="b.mp_logo" class="lf_club" :src="b.mp_logo" alt="">
							</div>
							
							<div :class="'lf_3 '+(b.ok_h=='F'?'lf_thru_F':'')">{{b.ok_h==18?'F':b.ok_h}}</div>
							<div class="lf_4" :class="parseInt(b.topar) < 0?'lf_thru_F':''">{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}</div>
							<div class="lf_5" :class="parseInt(b.today) < 0?'lf_thru_F':''">{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}</div>
							<div class="lf_6" v-for="r in radio">{{b['R'+r]}}</div>
							<div class="lf_7">{{b.total==0?'-':b.total}}</div>
						</div>
						<div v-if="checkplidv ==b.su_pl_id && zctype =='zc'" class="lf_match_info_box_con">
							<el-row>
								<el-col v-if="OutInFlag" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div class="lf_221">Hole</div>
										<template v-for="h in info.hole">
										<div v-if="h.mh_id <= 9"  class="lf_22">
											<div>{{h.mh_id}}</div>
											<div>{{h.mh_par}}</div>
										</div>
										</template>
										<div class="lf_11">
											<div>OUT</div>
											<div>{{jsPar('OUT')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img src="../../assets/images/r.png" alt=""></div>
									</div>
									<div class="lf_match_info_score_con" v-for="ii in radio">
										<div class="lf_221">R{{ii}}</div>
										<!-- 输 lf_e赢 lf_b 平 .lf_par -->
										<template v-for="h in info.hole">
										<div class="lf_22" :class="info.tp && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''" v-if="h.mh_id <= 9">{{info.cj && info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'OUT')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
								<el-col v-if="!OutInFlag||WebModel" :xs="24" :sm="12" :md="12">
									<div class="lf_match_info_con">
										<div v-if="!WebModel" class="lf_221">Hole</div>
										<template v-for="h in info.hole">
										<div v-if="h.mh_id > 9"  class="lf_22">
											<div>{{h.mh_id}}</div>
											<div>{{h.mh_par}}</div>
										</div>
										</template>
										<div class="lf_11">
											<div>IN</div>
											<div>{{jsPar('IN')}}</div>
										</div>
										<div class="lf_223" @click="OutInChange(OutInFlag)"><img style="transform: rotate(180deg);" src="../../assets/images/r.png" alt=""></div>
									</div>
									<div class="lf_match_info_score_con"  v-for="ii in radio">
										<div v-if="!WebModel" class="lf_221">R{{ii}}</div>
										<template v-for="h in info.hole">
											<!-- 输 lf_e赢 lf_b 平 .lf_par -->
										<div :class="info.tp && info.tp[checkplidv][ii][h.mh_id]?info.tp[checkplidv][ii][h.mh_id]:''" class="lf_22" v-if="h.mh_id > 9">{{info.cj && info.cj[checkplidv][ii][h.mh_id]?info.cj[checkplidv][ii][h.mh_id]:'-'}}</div>
										</template>
										<div class="lf_11">{{jsParScore(checkplidv,ii,'IN')}}</div>
										<div class="lf_223"></div>
									</div>
								</el-col>
							</el-row>
						</div>
					</template>
					<Ad1 v-if="(indext+1)%20==0" pos="singlematch" abs="SINGLEMATCH_SCORE"></Ad1>
				</template>
			</div>
		</el-col>
	</el-row>
	<Tips @aliclickshowhide="aliclickshowhide" :show="show"></Tips>
	<!-- <Ad></Ad> -->
</template>

<script>
import { result } from 'lodash';
import html2Canvas from '../../plugs/htmlToPDF';
import Tips from '../Tips.vue';
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
import axios from '../../axios/request';
import Ad1 from "../Ad1.vue";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                banner:{},
				info:{},
				pmlist:[],
				plname:'',
				checkplidv:'',
				cj:'cj',
				radio: '',
				cradio:'',
				value: '',
				likepl:[],
				zctype:'zc',//点击区域
				show:'0',
				showgroup:'',
				OutInFlag:true,
				WebModel:true,
		    }
		},
        name: 'Player',
        props: {
            msg: String,
			mt_id:String,
			flag:String,
			round_id:String
        },
		components: {
			Ad1,
			Tips
        },
		created() {
			 this.getData();
			//  this.$cookies.remove('likepl')
			 console.log('likpl',this.$cookies.get('likepl'));
			 if(this.$cookies.isKey('likepl')){
				console.log('likpl',this.$cookies.get('likepl'));
				this.likepl = JSON.parse(this.$cookies.get('likepl'));
			 }
			 this.systemlang = getSystemLang();
			 this.langs = getLangAll(this.systemlang);
			 this.cj = this.flag;
			 if(this.round_id > 0)
			 {
				this.radio = this.round_id;
			 }
			 else
			 {
				this.radio = 0;
			 }
			 var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			 if(deviceWidth>=768){
			 				 this.WebModel=true;
			 }else{
			 				 this.WebModel=false;
			 }
			 console.log('round','a:'+this.round_id,'b:'+this.radio);
		},
		watch:{
			radio:function(newValue,oldValue)
			{
				console.log(newValue,oldValue);
				if(newValue !== oldValue)
				{
					this.radio = newValue;
					this.getData1();
				}
				
			},
			cj:function(newValue,oldValue)
			{
				console.log(newValue,oldValue);
				if(newValue != oldValue)
				{
					console.log('show',this.radio);
					if(newValue == 'cj')
					{
						this.$emit('goInfo','cj','',this.radio);
					}
					else
					{
						this.$emit('goInfo','cj','tm',this.radio);
					}
					
					
				}
				
			},
		},
		methods:{
			getData(){
			//获取banner
			axios('matchs/match/matchtj.json',{otp:'playerScore',mt_id:this.mt_id,round_id:this.radio})
            .then((res)=>{
                let data = res.data.data;
                this.info = data;
				if(this.radio < 1)
				{
					this.radio = parseInt(data.info.mt_round);
				}
				
				this.cradio = parseInt(data.info.mt_round_num);
				// console.log(this.radio,'radio');
				
            });
			},
			checkplid(pl_id,stype){
				if(stype == 'zc')
				{
					this.zctype = stype;
				}
				else
				{
					this.zctype = stype;
				}
				
				if(this.checkplidv != pl_id)
				{
					this.checkplidv = pl_id;
				}
				else
				{
					this.checkplidv = '';
				}
				
			},
			getClass(pl_id)
			{
				//lf_course_name_data_con_select lf_course_name_data_con
				if(this.checkplidv == pl_id)
				{
					return 'lf_course_name_data_con_select lf_course_name_data_con';
				}
				return 'lf_course_name_data_con';

			},
			getData1(){
			//获取banner
			axios('matchs/match/matchtj.json',{otp:'playerScore',mt_id:this.mt_id,round_id:this.radio})
            .then((res)=>{
                let data = res.data.data;
                this.info = data;
				
				console.log(this.radio,'radio');
				
            });
			},
			likes(pl_id){
				console.log('1',pl_id);
				console.log(JSON.stringify(this.likepl));
				
				if(this.likepl.indexOf(pl_id) == -1)
				{
					this.likepl.push(pl_id);
					console.log('2',JSON.stringify(this.likepl));
				}
				else
				{
					
					this.likepl.splice(this.likepl.indexOf(pl_id),1);
					console.log('3',pl_id);
					console.log(JSON.stringify(this.likepl));
				}
				console.log(JSON.stringify(this.likepl));
				this.$cookies.set('likepl',JSON.stringify(this.likepl),-1);
			},
			jsPar($str)
			{
				if(!(this.info && this.info.hole))
				{
					return '-';
				}
				var  rsult = 0;
				if($str == 'OUT')
				{
					let hole = this.info.hole;
					for(let i in hole)
					{
						if(hole[i].mh_par && hole[i].mh_id <= 9)
						{
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if($str == 'IN')
				{
					let hole = this.info.hole;
					for(let i in hole)
					{
						if(hole[i].mh_par && hole[i].mh_id > 9)
						{
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if(rsult == 0)
				{
					return '--';
				}
				else
				{
					return rsult;
				}

			},
			jsParScore($pl,$rid,$str)
			{
				if(!(this.info && this.info.cj && this.info.cj[$pl] && this.info.cj[$pl][$rid]))
				{
					return '-';
				}

				var  rsult = 0;
				if($str == 'OUT')
				{
					let hole = this.info.cj[$pl][$rid];
					for(let i in hole)
					{
						if(hole[i] && i <= 9)
						{
							rsult += parseInt(hole[i]);
						}
					}
				}

				if($str == 'IN')
				{
					let hole = this.info.cj[$pl][$rid];
					for(let i in hole)
					{
						if(hole[i] && i > 9)
						{
							rsult += parseInt(hole[i]);
						}
					}
				}

				if(rsult == 0)
				{
					return '--';
				}
				else
				{
					return rsult;
				}
			},
			aliclickshowhide($s){
				if($s == '1')
				{
					this.show = '1';
				}
				else
				{
					this.show = '0';
				}
			},
			is_weixn(){  
			
			var ua = navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				
				return true;

			}
			return false;

			},
			showgroupS(s){
				if(s == this.showgroup)
				{
					this.showgroup = '';
				}
				else
				{
					this.showgroup = s;
				}

			},
			Douwn(){
				if(this.is_weixn())
				{
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if(lang == 'cn')
				{
					lang = 'zh-cn';
				}
				else if(lang == 'en')
				{
					lang = 'en-us';
				}
				else
				{
					lang = 'zh-cn';
				}
				let domain  = window.location.protocol+"//"+window.location.host;
				//技术统计
				// let cuurl = domain+'/index.php/Home/Match/expPersonalAllInfoPdf/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio;
				let url = domain+'/index.php/Home/Match/ExpResult/year/'+this.info.info.year+'/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio+'/mt_round/'+this.info.info.mt_round_num+'';
				window.open(url,'_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			OutInChange(a){
				if(a){
					this.OutInFlag=false
				}else{
					this.OutInFlag=true
				}
				console.log(this.OutInFlag)
			}
		}
    }
</script>

<style scoped="scoped">
	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.2rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.14rem;
	}
	.lf_match_info_box{
		padding: 0.35rem 0px 0.1rem;
		font-size: 0.14rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lf_match_info_box_r{
		display: flex;
		justify-content: flex-end;
	}
	.lf_course_bogo{
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}
	.lf_course_bogo_des{
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}
	.lf_course_name_data_box{
		background-color: #FAFAFA;
	}
	.lf_score_tips{
		display: flex;
		align-items: center;
		color: #999;
		font-size: 0.14rem;
	}
	.lf_score_tips div{
		margin: 0 0.2rem;
	}
	.lf_score_tips div text{
		color: #C0153E;
	}
	.lf_score_tips div img{
		width: 20px!important;
		height: 20px!important;
	}
	.lf_tec_download{
		width: 96%;
		color: #C0153E;
		font-size: 0.14rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}
	.lf_tec_download div{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_tec_download img{
		width: 24px;
		height: 26px;
		margin-left: 0.2rem;
	}
	.lf_course_name_data_tit{
		width: 96%;
		border-bottom: 1px solid #efefef;
		color: #333;
		font-size: 0.16rem;
		height: 0.6rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_con{
		width: 96%;
		color: #999;
		font-size: 0.16rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_con_select{
		background-color: #FFF1F2;
	}
	.lf_course_name_data_tit div,.lf_course_name_data_con div{
		width: 9.8%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_thru_F{
		color: #C0153E;
	}
	.lf_2{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
	}
	.lf_2 img{
		margin-right: 0.2rem;
	}
	.lf_course_name_data_con .lf_2{
		justify-content: flex-start;
		cursor: pointer;
	}
	.lf_course_name_data_tit .lf_1,.lf_course_name_data_con .lf_1{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 12%;
	}
	.lf_1 img{
		width: 14px;
		height: 14px;
		margin: 0 0.2rem;
		cursor: pointer;
	}
	.lf_2 .lf_country{
		width: 20px;
		height: 14px;
		margin-right: 10px;
	}
	.lf_2 .lf_club{
		width: 0.73rem;
		height: 0.25rem;
		margin-left: 10px;
	}
	.lf_6{
		width: 0.6rem!important;
	}
	.lf_match_info_box_con{
		margin: 0 2%;
		background-color: #fff;
	}
	.lf_match_info_con{
		display: flex;
		box-sizing: border-box;
		border-bottom: 1px solid #EFEFEF;
	}
	.lf_match_info_con div{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
	}
	
	.lf_match_info_con .lf_21,.lf_match_info_con .lf_22{
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_22{
		display: flex;
		flex-direction: column;
		height: 0.8rem;
		flex-shrink: 0;
		width: 0.5rem;
	}
	.lf_match_info_con .lf_22 div,.lf_match_info_con .lf_11 div{
		height: 0.35rem;
	}
	.lf_match_info_con .lf_11{
		width: 0.6rem;
		color: #C0153E;
		flex-shrink: 0;
		flex-direction: column;
		height: 0.8rem;
	}
	.lf_match_info_score_con{
		display: flex;
		box-sizing: border-box;
	}
	.lf_match_info_score_con .lf_221{
		color: #C0153E;
		font-weight: bold;
	}
	.lf_match_info_score_con div{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
	}
	
	.lf_match_info_score_con .lf_21,.lf_match_info_score_con .lf_22{
		width: 1.1rem;
		height: 0.5rem;
		flex-grow: 1;
		flex-shrink: 0;
	}
	.lf_match_info_score_con .lf_22{
		display: flex;
		flex-direction: column;
		height: 0.5rem;
		flex-shrink: 0;
		width: 0.5rem;
	}
	.lf_match_info_score_con .lf_22 div{
		height: 0.35rem;
	}
	.lf_match_info_score_con .lf_11{
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}
	.lf_match_info_score_con .lf_b{
		color: #B6081D;
	}
	.lf_match_info_score_con .lf_par{
		color: #333;
	}
	.lf_match_info_score_con .lf_e{
		color: #2C2AE3;
	}
	.lf_match_info_con .lf_11 div{
		color: #C0153E!important;
	}
	.lf_match_info_con .lf_221{
		color: #C0153E!important;
		font-weight: bold;
	}
	.lf_Playoff_box{
		background-color: #FAFAFA;
	}
	.lf_Playoff_tit{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #EFEFEF;
		color: #333;
		font-size: 0.18rem;
		width: 96%;
		margin: 0 auto;
		height: 0.5rem;
	}
	.lf_Playoff_con{
		color: #999;
		font-size: 0.16rem;
		display: flex;
		align-items: center;
		height: 0.7rem;
		width: 96%;
		margin: 0 auto;
	}
	.lf_off_1{
		width: 100px;
		margin-right: 1rem;
		font-size: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 35px;
	}
	.lf_off_1_win{
		background: inherit;
		background-color: rgba(182, 8, 29, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #FFFFFF;
		
	}
	.lf_off_2{
		display: flex;
		width: 2rem;
	}
	.lf_off_2 img{
		width: 20px;
		height: 14px;
		margin-right: 10px;
	}
	.lf_off_3{
		display: flex;
		width: 2rem;
		align-items: center;
		justify-content: center;
	}
	.lf_team_box{
		border-top: 1px solid #efefef;
	}
	.lf_223{
		display: none!important;
	}
	
	@media screen and (max-width: 768px) {
		.lf_match_info_box{
			padding: 0.35rem 10px;
			flex-direction: column;
		}
		.lf_course_name_data_tit .lf_6,.lf_course_name_data_con .lf_6,.lf_course_name_data_tit .lf_8,.lf_course_name_data_con .lf_8{
			display: none!important;
		}
		.lf_course_name_data_tit div,.lf_course_name_data_con div{
			width: 14%!important;
		}
		/* .lf_1.div{
			width: 17%!important;
		} */
		.lf_1 img{
			margin: 0 0.1rem;
		}
		.lf_tec_download,.lf_course_name_data_tit{
			height: 0.7rem;
		}
		.lf_tec_download{
			justify-content: space-between;
		}
		.lf_score_tips div:nth-of-type(n+4){
			display: none!important;
		}
		.lf_tec_download_img_box text{
			display: none;
		}
		.lf_match_info_con,.lf_match_info_score_con{
			justify-content: flex-end!important;
		}
		.lf_match_info_con .lf_221,.lf_match_info_score_con .lf_221{
			flex-grow: 1;
		}
		.lf_match_info_con .lf_22,.lf_match_info_score_con .lf_22{
			width: 0.6rem;
			flex-grow: unset;
			flex-shrink: 0;
		}
		.lf_223{
			display: flex!important;
			width: 0.5rem;
		}
		.lf_223 img{
			height: 0.5rem;
			width: 0.4rem;
		}
		.d_title,.d_title span{
			font-size: 0.26rem;
		}
	}
</style>
