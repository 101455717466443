<template>
	<div class="banner_top" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner :type="0" abs="OTHER_TOP"></banner>
	</div>
	<Btn cd="cj"></Btn>



	<!-- <videoPlayer url="https://other1-live.situne.cn/phone/other-18.m3u8"></videoPlayer> -->


	<div class="lf_match_banner_box">
		<div class="lf_opacity_div">

			<div class="lf_opacity_div_1">{{matchInfo?matchInfo.ColumnName:''}}</div>
			<div class="lf_opacity_div_2">{{matchInfo?matchInfo.mttime1:''}}</div>
			<div class="lf_opacity_div_3">
				<img src="../../assets/images/u1522.png" alt="">
			{{matchInfo?matchInfo.ColumnPlace:''}}
			</div>
		</div>
	</div>
	<div style="clear: both;"></div>

	<div v-if="zb.vdr_vod_url && zb.mt_id == matchInfo.match_id" class="video_btn_body">
		<div class="video_title">ROUND {{zb.mt_round}}</div>
		<div class="video_btn">
			<div class="video_btn_text">{{langs.matchyf_sphm}}<!--视频画面--></div>
			<div class="video_btn_btn">

				<div class="switch">
					<input type="checkbox" v-model="showzb">
					<span class="checked"></span>
				</div>

			</div>
		</div>
	</div>
	<!-- <div class="video">
		<img src="../../assets/images/u33.png" />
	</div> -->
	<div class="all_body" v-if="showzb">
		<videoPlayer v-if="zb.vdr_vod_url && zb.mt_id == matchInfo.match_id" :url="zb.vdr_vod_url"></videoPlayer>
	</div>
	<!-- 	
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_tab_tit_box">
				<div class="lf_tab_box">
					<div @click="goInfo('match','ssgc')" :class="tFlag=='match'?'lf_tab_box_select':''">{{langs.Sh}}
					</div>
					<div @click="goInfo('cj',bFlag=='per'?'per':'')" :class="tFlag=='cj'?'lf_tab_box_select':''">
						{{langs.Leaderboard1}}</div>
					<div @click="goInfo('news','xw')" :class="tFlag=='news'?'lf_tab_box_select':''">{{langs.News}}</div>

				</div>
			</div>
		</el-col>
	</el-row> -->
	<!-- <el-row type="flex" justify="center" v-if="tFlag == 'match' || tFlag == 'news' || tFlag == 'cj'">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_tab_tit_box lf_tab_tit_box999" style="justify-content: center;">
				<div class="lf_tab_little_box">
					<div v-if="tFlag == 'match'" @click="goInfo('match','ssgc')"
						:class="bFlag=='ssgc'?'lf_tab_box_select':''">{{langs.FactSheet}}</div>
					<div v-if="tFlag == 'match'" @click="goInfo('match','ssxx')"
						:class="bFlag=='ssxx'?'lf_tab_box_select':''">{{langs.ShGk}}</div>
					<div v-if="tFlag == 'match'" @click="goInfo('match','csqy')"
						:class="bFlag=='csqy'?'lf_tab_box_select':''">{{langs.Field}}</div>
					<div v-if="tFlag == 'match'" @click="goInfo('match','qcjs')"
						:class="bFlag=='qcjs'?'lf_tab_box_select':''">{{langs.CourseIntroduction}}</div>
					<div v-if="tFlag == 'match'" @click="goInfo('match','fzb')"
						:class="bFlag=='fzb'?'lf_tab_box_select':''">{{langs.TeeTimes}}</div>
					<div v-if="tFlag == 'match'" @click="goInfo('match','jstj')"
						:class="bFlag=='jstj'?'lf_tab_box_select':''">{{langs.Stats}}</div>
					<div v-if="tFlag == 'news'" @click="goInfo('news','xw')"
						:class="bFlag=='xw'?'lf_tab_box_select':''">{{langs.News}}</div>
					<div v-if="tFlag == 'news'" @click="goInfo('news','tp')"
						:class="bFlag=='tp'?'lf_tab_box_select':''">{{langs.Pictures}}</div>
					<div v-if="tFlag == 'news'" @click="goInfo('news','sp')"
						:class="bFlag=='sp'?'lf_tab_box_select':''">{{langs.Video}}</div>

					<div v-if="tFlag == 'cj' && bFlag!='per'" @click="goInfo('cj','lxb')"
						:class="bFlag=='lxb'?'lf_tab_box_select':''">{{langs.cj_lxb}}</div>
					<div v-if="tFlag == 'cj' && bFlag!='per'" @click="goInfo('cj','zdcj')"
						:class="bFlag=='zdcj'?'lf_tab_box_select':''">{{langs.cj_zd}}</div>
					<div v-if="tFlag == 'cj' && bFlag!='per' && matchInfo.tmFlag =='1' " @click="goInfo('cj','tm')"
						:class="bFlag=='tm'?'lf_tab_box_select':''">{{langs.cj_tm}}</div>
				</div>
			</div>
		</el-col>
	</el-row> -->

	<div class="all_body" >
		<ScoreHBH @goInfo="goInfo" :round_id="round_id" jscj="1" flag="cj" v-if="tFlag== 'cj'  && ((bFlag=='zdcj' && WebModel) || bFlag=='zdcjzd') && matchInfo.ID"
			:mt_id="matchInfo.ID+''"></ScoreHBH>
		<ScoreA @goInfo="goInfo" :round_id="round_id" jscj="1" flag="cj" v-if="tFlag== 'cj'  && bFlag=='zdcj' && !WebModel && matchInfo.ID" :mt_id="matchInfo.ID+''"></ScoreA>
	</div>
	<Footer :year="year" :mt_id="matchInfo.ID"></Footer>
	<Footer></Footer>
	<Footer1 :tFlag="tFlag"></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import banner from "../../components/banner.vue";
	import Footer1 from "../../components/Footer1.vue";
	import Ad from "../../components/Ad.vue";
	import Player from "../../components/Player.vue";
	import Index from "../../components/Index.vue";
	import IndexSg from "../../components/IndexSg.vue"; //IndexSg
	import Group from "../../components/Match/Group.vue";
	import Course from "../../components/Match/Course.vue";
	import TecStatistics from "../../components/Match/TecStatistics.vue";
	import ScoreA from "../../components/Match/ScoreA.vue";
	import NewListmore from "../../components/NewListmore.vue";
	import videoPlayer from "../../components/videoPlayer.vue";
	import ScoreT from "../../components/Match/ScoreT.vue";
	import BDS from "../../components/Match/BDS.vue";
	import ScoreHBH from "../../components/Match/ScoreHBH.vue"
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	import axios from '../../axios/request';
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			return {
				value: false,
				langs: '',
				systemlang: 'cn',
				opacityF: true,
				round_id: 0,
				options: [{
						value: 'Option1',
						label: 'Option1',
					},
					{
						value: 'Option2',
						label: 'Option2',
					},
					{
						value: 'Option3',
						label: 'Option3',
					},
					{
						value: 'Option4',
						label: 'Option4',
					},
					{
						value: 'Option5',
						label: 'Option5',
					},
				],
				matchInfo: {},
				zb: {},
				showzb:false,
				year: '',
				bannershow:[],
				WebModel:true,
				tFlag: 'match', //match:赛事信息 cj:成绩信息  news:新闻信息
				bFlag: 'ssgc', //ssxx:赛事信息 csqy:参赛球员 qcjs:球场介绍 fzb:分组表 jstj:技术统计  tm:团队成绩 zdcj:逐洞成绩 lxb:领先榜 per:个人比洞成绩
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			Ad,
			Player,
			Index,
			Group,
			Course,
			TecStatistics,
			ScoreA,
			ScoreHBH,
			NewListmore,
			IndexSg,
			ScoreT,
			BDS,
			videoPlayer,
			banner
		},
		watch: {
			showzb(val) {
				
			}
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			let id = this.$route.query.id;
			let type = this.$route.query.type;
			let bFlag = this.$route.query.bFlag ? this.$route.query.bFlag : '';
			if (type) {
				this.tFlag = type;
				this.bFlag = bFlag;
			}
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
				if (deviceWidth >= 768) {
					this.WebModel = true;
				} else {
					this.WebModel = false;
				}
			//获取banner
			axios('matchs/match/matchinfo.json', {
					id: id
				})
				.then((res) => {
					let data = res.data.data;
					this.matchInfo = data;
					console.log(this.matchInfo);
					this.year = this.matchInfo.ColumnStart.substring(0, 4);
					if (data.mZt == 'c' && this.bFlag == '' && this.tFlag == 'cj') {
						this.bFlag = 'zdcj';
					} else if (this.bFlag == '' && this.tFlag == 'cj') {
						this.bFlag = 'lxb';
					}
					if (data.perFlag == '1' && this.tFlag == 'cj') {
						this.bFlag = 'per';
					}
					console.log('b_flag', this.bFlag);
				});

			this.getZB();

		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		mounted() {
			
			window.onresize = () => {
				
				var deviceWidth = document.documentElement.clientWidth || window.innerWidth
				if (deviceWidth >= 768) {
					this.WebModel = true;
					
				} else {
					this.WebModel = false;
				}
				
			}
		},
		methods: {
			setbannershow(abs, isshow) {
				// console.log(abs,isshow);
				this.bannershow[abs] = isshow;
			},
			getZB() {
				axios('matchs/match/zb.json')
					.then((res) => {
						let data = res.data;
						if (data.msgCode == '200') {
							this.zb = data.data;
							this.showzb = true;
						}

					});
			},
			changeFun(index, a) {
				var that = this;
				that.$data.opacityF = false;
				setTimeout(function() {
					that.$data.opacityF = true;
				}, 400)
			},
			goInfo(tFlag, bFlag, round_id) {
				let type1 = this.$route.query.type;
				let bFlag1 = this.$route.query.bFlag ? this.$route.query.bFlag : '';
				if (tFlag != type1 || bFlag1 != bFlag) {


					let routeUrl = this.$router.resolve({
						path: "/Match",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: tFlag,
							bFlag: bFlag
						}
					});
					// console.log(routeUrl.href);
					window.open(routeUrl.href, '_self');
					return false;
				}
				console.log('cs', tFlag, bFlag, round_id);

				if (round_id > 0) {
					this.round_id = round_id;
				}
				this.tFlag = tFlag;
				this.bFlag = bFlag;



			}
		}

	}
</script>


<style scoped="scoped">
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}

	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}

	.lf_tab_little_box {
		height: 30px;
		display: flex;
		align-items: center;
	}

	.lf_tab_little_box div {
		padding: 0 16px;
		height: 0.14rem;
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
		cursor: pointer;
	}

	.lf_tab_little_box div:nth-last-of-type(1) {
		border-right: unset;
	}

	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}

	.lf_tab_box {
		width: 330px;
		height: 30px;
		display: flex;
		align-items: center;
		margin-top: 40px;
	}

	.lf_tab_box div {
		width: 110px;
		height: 30px;
		font-size: 0.16rem;
		color: #999;
		border: 1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-radius: 5px;
		cursor: pointer;
	}

	.lf_tab_box div:nth-of-type(1) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		position: relative;
		left: 1px;
	}

	.lf_tab_box div:nth-of-type(2) {
		border-radius: 0;
	}

	.lf_tab_box div:nth-of-type(3) {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		position: relative;
		left: -1px;
	}

	.lf_tab_box .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
		position: relative;
		z-index: 2;
	}

	.lf_opacity_div {
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;

		color: #333333;
		height: 4rem;
		z-index: 99;

	}



	.lf_opacity_div_1 {
		height: 0.7rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.4rem;
		letter-spacing: 0.01rem;
		color: #C0153E;
		max-width: 100%;
		padding: 0 0.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		overflow: hidden;
		margin: 0.1rem auto 0;
		width: fit-content;
		border-bottom: 1px solid #fff;
	}

	.lf_opacity_div_2 {
		width: 100%;

		text-align: center;
		font-size: 0.24rem;
		color: #C0153E;
		height: 0.25rem;
		line-height: 0.25rem;
		margin-top: 0.1rem;
	}

	.lf_opacity_div_3 {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		font-size: 0.24rem;
		color: #333333;
		height: 0.25rem;
		line-height: 0.25rem;
		margin-top: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_opacity_div_3 img{
		width: 0.26rem;
		height: 0.26rem;
		display: flex!important;
	}


	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.2rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.14rem;
	}

	.lf_match_banner_box {
		height: 1.8rem;
		width: 100%;
		position: relative;
		overflow: hidden;

	}

	.video_btn_body {
		width: 12.9rem;
		height: 0.72rem;
		margin: 0 auto;
		overflow: hidden;
		line-height: 0.72rem;

	}

	.video_title {
		height: 0.72rem;
		line-height: 0.72rem;
		float: left;
		margin-left: 0.3rem;
		color: #C0153E;
		font-size: 0.26rem;
		font-weight: bold;
	}

	.video_btn {
		height: 0.72rem;
		float: right;
		margin-right: 0.3rem;
	}

	.video_btn_btn {
		float: right;
		height: 0.26rem;
		margin-top: 0.23rem;
		margin-left: 0.1rem;
	}

	.video_btn_text {
		height: 0.72rem;
		line-height: 0.72rem;
		color: #C0153E;
		font-size: 0.2rem;
		float: left;
	}

	.video {
		width: 12.9rem;
		height: 7.2rem;
		margin: 0 auto;
		overflow: hidden;
		margin-bottom: 0.2rem;
	}



	.lf_match_info_box_text {
		padding: 0.24rem 10px;
		font-size: 0.14rem;
		color: #333;
		white-space: pre-wrap;
	}

	.lf_match_info_box_con {
		padding: 0.24rem 10px;
	}

	.lf_match_info_con {
		display: flex;
		background-color: #FAFAFA;
		box-sizing: border-box;
	}

	.lf_match_info_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
		border-right: 1px solid #F5F5F5;
		border-bottom: 1px solid #F5F5F5;
	}

	.lf_match_info_con .lf_1,
	.lf_match_info_con .lf_12 {
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}

	.lf_match_info_con .lf_11 {
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}

	.lf_match_info_con .lf_2 {
		flex-shrink: 0;
		width: 0.5rem;
	}

	.lf_match_champion_his_box {
		padding: 0.24rem 10px;
		font-size: 0.16rem;

	}

	.lf_match_champion_his_tit {
		display: flex;
		background-color: #FAFAFA;
		height: 0.8rem;
		align-items: center;
		border-bottom: 1px solid #F5F5F5;
		color: #333;
	}

	.lf_champion_his_1 {
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_2 {
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		position: relative;
	}

	.lf_player_card {
		position: absolute;
		left: 1.5rem;
		top: 0;
		width: 5.2rem;
		height: 2.5rem;
		box-shadow: 0px 0px 4px 2px #f1f1f1;
		display: flex;
		border-radius: 5px;
		background-color: #fff;
		z-index: 9;
	}

	.lf_player_card_img {
		margin: 0.3rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
	}

	.lf_player_card_con {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.lf_player_card_con_1 {
		font-size: 0.24rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.2rem;
	}

	.lf_player_card_con_1 img {
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}

	.lf_player_card_con_2 {
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.35rem;
		display: flex;
		align-items: center;
	}

	.lf_champion_his_3 {
		width: 1.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_3 img {
		width: 20px;
		height: 14px;
	}

	.lf_champion_his_4 {
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_5 {
		width: 3.1rem;
		display: flex;
		flex-direction: column;
	}

	.lf_champion_his_6 {
		width: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_7 {
		width: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_rd_tit {
		width: 100%;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_rd_box {
		width: 100%;
		height: 0.4rem;
		display: flex;
		flex-direction: row;
	}

	.lf_match_champion_his_con .lf_rd_box {
		height: ;
	}

	.lf_rd_box div {
		flex-grow: 1;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_match_champion_his_con {
		display: flex;
		background-color: #FAFAFA;
		color: #999;
	}



	/* css部分 */
	.switch {
		position: relative;
		width: 0.7rem;
		height: 0.26rem;
	}

	input[type=checkbox] {
		position: absolute;
		margin: 0;
		padding: 0;
		/* 复选框宽高都为100%，保证点击哪都可以触发移动 */
		height: 100%;
		width: 100%;
		z-index: 99;
		opacity: 0;
	}

	span.checked {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 20px;
		background-color: #dcdfe6;
		z-index: -1;
		transition: all .5s;
	}

	span.checked::before {
		content: '';
		position: absolute;
		top: 0rem;
		left: 0;
		width: 0.26rem;
		height: 0.26rem;
		border-radius: 50%;
		background-color: #fff;
		transition: all .5s;
	}

	/* 复选框被选中时，让圆圈的left=26px */
	input[type=checkbox]:checked+span.checked::before {
		left: 0.44rem;
	}

	/* 复选框被选中时，让开关背景变色 */
	input[type=checkbox]:checked+span.checked {
		background-color: #C0153E;
	}
@media screen and (min-width: 768px) and (max-width:1199px) {
	    
		.all_body{
			width: 100%;
		}
	}
	@media screen and (max-width: 768px) {
		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}

		.lf_tab_tit_box {
			align-items: unset;
			overflow: scroll;
		}

		.lf_tab_tit_box999 {
			align-items: unset;
			overflow: scroll;
			justify-content: unset !important;
		}

		.lf_tab_box {
			margin-top: 0.2rem;
		}

		.lf_match_banner_box {
			width: 100%;
			position: relative;
		}

		.lf_match_banner_box_img {
		}

		.lf_opacity_div {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			
			color: #fff;
			height: 100%;
			z-index: 99;
			padding: 0.1rem 0;
		}

		.lf_opacity_div_1 {
			height: 0.7rem;
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 0.4rem;
			letter-spacing: 0.01rem;
			color: #C0153E;
			max-width: 100%;
			padding: 0 0.6rem;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			overflow: hidden;
			margin: 0.1rem auto 0;
			width: fit-content;
			border-bottom: 1px solid #fff;
		}
		
		.lf_opacity_div_2 {
			width: 100%;
		
			text-align: center;
			font-size: 0.24rem;
			color: #C0153E;
			height: 0.25rem;
			line-height: 0.25rem;
			margin-top: 0.1rem;
		}
		
		.lf_opacity_div_3 {
			width: 100%;
			margin: 0 auto;
			text-align: center;
			font-size: 0.24rem;
			color: #333333;
			height: 0.25rem;
			line-height: 0.25rem;
			margin-top: 0.2rem;
		}
		
		.video_btn_body{
			width:100%;
		}




		.lf_12 {
			display: none !important;
		}

		.lf_match_info_con {
			justify-content: flex-end !important;
		}

		.lf_match_info_con .lf_2 {
			width: 0.6rem;
		}

		.lf_champion_his_5,
		.lf_champion_his_6 {
			display: none;
		}

		.lf_player_card {
			position: absolute;
			left: 0;
			top: 0.4rem;
			width: 5.2rem;
			height: 2.5rem;
			box-shadow: 0px 0px 4px 2px #f1f1f1;
			display: flex;
			border-radius: 5px;
			background-color: #fff;
			z-index: 9;
		}

		.lf_tab_box div {
			font-size: 0.24rem;
		}

		.lf_tab_little_box div {
			font-size: 0.24rem;
		}

		.d_title,
		.d_title span {
			font-size: 0.26rem;
		}

		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}

		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}
	}
</style>