<template>

	<div class="all_body">
		<div class="head_top">

			<div class="lf_match_info_box">
				<div class="d_title">{{langs.jstj_jstj}} | <span>{{langs.jstj_jstj_en}}</span></div>
			</div>
			<div class="search hand az">
				<div class="search_pos">
					<span class="kindbox" @click.stop="changeazshow">
						{{typelist[type]}}
					</span>
					<img src="../../assets/images/u182.png" @click.stop="changeazshow" />
					<div class="select-back" v-if="azshow" @click.stop="changeazshow"></div>
					<div class="select-right into" v-if="azshow">
						<div class="select_title">{{typelist[type]}}</div>
						<div class="select_socl">
							<div class="select_item " :class="item.value==type?'select_item_hover':''" v-for="item in w" @click="changetype(item.value)">{{item.label}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="lf_match_info_box">
			<div class="lf_group_tit_left">
				<el-radio-group v-model="radio">
					<el-radio v-if="info.info && info.info.mt_round" v-for="(i,inex) in info.list"
						:label="inex">{{langs.text_di}}{{inex}}{{langs.text_lun}}</el-radio>
				</el-radio-group>
			</div>


		</div>
		<el-row id="pdf" type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">

				<div class="lf_course_name_data_box">
					<div class="lf_tec_download">
						<!-- <el-select v-model="value" size="small" class="lf_hide_select m-2">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value" />
						</el-select> -->
						<div @click="Douwn" class="hand">
							{{langs.DownloadPDF}} <img src="../../assets/images/u2818.png" alt="">
						</div>
					</div>
					<div class="lf_course_name_data_tit">
						<div class="lf_1">{{langs.stats_paiming}}</div>
						<div class="lf_2">{{langs.stats_qiuyuan}}</div>
						<div class="lf_3">{{langs.stats_chengji}}</div>

						<div class="lf_4" :class="(sorts_value == value||sorts_value == value+'|dao' ? 'lf_select ':'')+(type==value?'lf_typeshow':'lf_typehide')" @click="sorts(value)">
							<text>{{langs.stats_Today}}</text>{{langs.stats_Score}}
							<img class="lf_up_d_img" v-if="sorts_value == value" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == value+'|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_5" :class="(sorts_value == 'str_putts_pround'||sorts_value == 'str_putts_pround|dao' ? 'lf_select ':'')+(type=='str_putts_pround'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_putts_pround')">
							<text>{{langs.stats_Today1}}</text>{{langs.stats_Putts}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_putts_pround'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_putts_pround|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_6" :class="(sorts_value == 'str_driving_accuracy'||sorts_value == 'str_driving_accuracy|dao' ? 'lf_select ':'')+(type=='str_driving_accuracy'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_driving_accuracy')">
							<text>{{langs.stats_Drive}}</text>{{langs.stats_Accuracy}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_driving_accuracy'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_driving_accuracy|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_7" :class="(sorts_value == 'str_sand_saves'||sorts_value == 'str_sand_saves|dao' ? 'lf_select ':'')+(type=='str_sand_saves'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_sand_saves')">
							<text>{{langs.stats_Bunker}}</text>{{langs.stats_Save}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_sand_saves'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_sand_saves|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_8" :class="(sorts_value == 'str_driving_distance'||sorts_value == 'str_driving_distance|dao' ? 'lf_select ':'')+(type=='str_driving_distance'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_driving_distance')">
							<text>{{langs.stats_Average}}</text>{{langs.stats_DrivingDistance}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_driving_distance'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_driving_distance|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_9" :class="(sorts_value == 'str_one_putts'||sorts_value == 'str_one_putts|dao' ? 'lf_select ':'')+(type=='str_one_putts'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_one_putts')">
							<text>{{langs.stats_PerRound}}</text>{{langs.stats_OnePutt}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_one_putts'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_one_putts|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_10" :class="(sorts_value == 'str_gir'||sorts_value == 'str_gir|dao' ? 'lf_select ':'')+(type=='str_gir'?'lf_typeshow':'lf_typehide')" @click="sorts('str_gir')">
							<text>{{langs.stats_Par}}</text>{{langs.stats_GIR}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_gir'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_gir|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_11" :class="(sorts_value == 'str_putts_pgir'||sorts_value == 'str_putts_pgir|dao' ? 'lf_select ':'')+(type=='str_putts_pgir'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_putts_pgir')">
							<text>{{langs.stats_GIR1}}</text>{{langs.stats_AveragePutting}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_putts_pgir'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_putts_pgir|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
						<div class="lf_12" :class="(sorts_value == 'str_scramble'||sorts_value == 'str_scramble|dao' ? 'lf_select ':'')+(type=='str_scramble'?'lf_typeshow':'lf_typehide')"
							@click="sorts('str_scramble')">
							<text>{{langs.stats_Scrambling}}</text>{{langs.stats_Save1}}
							<img class="lf_up_d_img" v-if="sorts_value == 'str_scramble'" src="../../assets/images/seq2.png" alt="">
							<img class="lf_up_d_img" v-else-if="sorts_value == 'str_scramble|dao'" src="../../assets/images/seq3.png" alt="">
							<img class="lf_up_d_img" v-else src="../../assets/images/seq1.png" alt="">
						</div>
					</div>
					<template v-if="info.list && info.list[radio] && info.list[radio].length>0" v-for="(b,index) in info.list[radio]">
						<div class="banner" v-if="index%20==0&&index!=0" v-show="bannershow && bannershow['MATCH_STATS_CENTER'] == '1'">
							<banner  @EventBack='setbannershow' :type="2" abs="MATCH_STATS_CENTER"></banner>
						</div>
						<div class="lf_course_name_data_con">
							<div class="lf_1">{{b.rank}}</div>
							<div class="lf_2">
								<router-link target="_blank"
									:to="{path:'/playerInfo',query:{pl_id:b.su_pl_id-200000,lang:systemlang}}">
								
								<div  class="center_name">
									<div class="lf_cn_name_ hoverUnderLine">
										<img v-if="b.pl_country" :src="b.pl_country" alt="">
										{{b.pl_cn_name}}{{b.pl_type=='AMA'?'(A)':''}}
									</div>
									<div class="lf_en_name_ hoverUnderLine">{{b.pl_en_name}}</div>
									<div class="headlogo" v-if="b.zhanzhu&&b.zhanzhu.length>0">
										<banner :type="2" datatype="1" :dataurl="b.zhanzhu"></banner>
									</div>
								</div>
								</router-link>
							</div>
							
							<!-- 45679,10 -->
							<div class="lf_3" :style="b.today!='E'&&b.today<0?'color:#C0153E;':''">{{b.today!='E'&&b.today>0?("+"+b.today):b.today}}</div>
							<div class="lf_4" :class="(sorts_value == value||sorts_value == value+'|dao' ? 'lf_select ':'')+(type==value?'lf_typeshow':'lf_typehide')" :style="b[value]!='E'&&b[value]<0?'color:#C0153E;':''">{{b[value]>0?b[value]:'-'}}{{b[value]>0?hz[value]:''}}</div>
							<div class="lf_5" :class="(sorts_value == 'str_putts_pround'||sorts_value == 'str_putts_pround|dao' ? 'lf_select ':'')+(type=='str_putts_pround'?'lf_typeshow':'lf_typehide')">{{b.str_putts_pround?b.str_putts_pround:'-'}}</div>
							<div class="lf_6" :class="(sorts_value == 'str_driving_accuracy'||sorts_value == 'str_driving_accuracy|dao' ? 'lf_select ':'')+(type=='str_driving_accuracy'?'lf_typeshow':'lf_typehide')">
								{{b.str_driving_accuracy?parseFloat(b.str_driving_accuracy).toFixed(1):'-'}}{{b.str_driving_accuracy>0?'%':''}}
							</div>
							<div class="lf_7" :class="(sorts_value == 'str_sand_saves'||sorts_value == 'str_sand_saves|dao' ? 'lf_select ':'')+(type=='str_sand_saves'?'lf_typeshow':'lf_typehide')">{{b.str_sand_saves?b.str_sand_saves.toFixed(1):'-'}}{{b.str_sand_saves>0?'%':''}}
							</div>
							<div class="lf_8" :class="(sorts_value == 'str_driving_distance'||sorts_value == 'str_driving_distance|dao' ? 'lf_select ':'')+(type=='str_driving_distance'?'lf_typeshow':'lf_typehide')">{{b.str_driving_distance?parseFloat(b.str_driving_distance).toFixed(1):'-'}}</div>
							<div class="lf_9" :class="(sorts_value == 'str_one_putts'||sorts_value == 'str_one_putts|dao' ? 'lf_select ':'')+(type=='str_one_putts'?'lf_typeshow':'lf_typehide')">{{b.str_one_putts?b.str_one_putts:'-'}}</div>
							<div class="lf_10" :class="(sorts_value == 'str_gir'||sorts_value == 'str_gir|dao' ? 'lf_select ':'')+(type=='str_gir'?'lf_typeshow':'lf_typehide')">{{b.str_gir?b.str_gir.toFixed(1):'-'}}{{b.str_gir>0?'%':''}}</div>
							<div class="lf_11" :class="(sorts_value == 'str_putts_pgir'||sorts_value == 'str_putts_pgir|dao' ? 'lf_select ':'')+(type=='str_putts_pgir'?'lf_typeshow':'lf_typehide')">{{b.str_putts_pgir?b.str_putts_pgir.toFixed(1):'-'}}</div>
							<div class="lf_12" :class="(sorts_value == 'str_scramble'||sorts_value == 'str_scramble|dao' ? 'lf_select ':'')+(type=='str_scramble'?'lf_typeshow':'lf_typehide')">{{b.str_scramble?b.str_scramble.toFixed(1):'-'}}{{b.str_scramble>0?'%':''}}</div>
						</div>
						<!-- <Ad1 v-if="(index+1)%20==0" pos="singlematch" abs="SINGLEMATCH_STATS"></Ad1> -->
					</template>
					<div v-else>
						<empty></empty>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<Tips @aliclickshowhide="aliclickshowhide" :show="show"></Tips>

</template>

<script>
	import html2Canvas from '../../plugs/htmlToPDF';
	import axios from '../../axios/request';
	import Ad1 from "../Ad1.vue";
	import Tips from '../Tips.vue';
	import banner from "../banner.vue";
	import empty from "../empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from '../../lang';
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				list: {},
				pmlist: [],
				plname: '',
				radio: '',
				info: {},
				oinfo: {},
				show: '0',
				s: 's', //b 大 s 小
				value: 'topar',
				hz: {
					'topar': '',
					'str_putts_pround': '',
					'str_driving_accuracy': '%',
					'str_sand_saves': '%',
					'str_driving_distance': '',
					'str_gir': '%',
					'str_one_putts': '',
					'str_putts_pgir': '',
					'str_scramble': '%'
				},
				sorts_value: '',
				pdfDonw: false,
				sorttype: '',
				w:[],
				azshow:false,
				type:"str_putts_pround",
				typelist:{},
				banner:[],
				bannershow:[]
			}
		},
		name: 'Player',
		props: {
			msg: String,
			mt_id: String,
		},
		components: {
			Ad1,
			Tips,
			banner,
			empty
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			axios('matchs/match/matchtj.json', {
					otp: 'stats',
					mt_id: this.mt_id
				})
				.then((res) => {
					let data = res.data.data;
					this.info = data;
					this.oinfo = JSON.parse(JSON.stringify(data));
					this.radio = data.info.mt_round + '';
					console.log(this.radio, 'radio');
					if(this.systemlang == "en"){
						for(var k in this.info.list){
							for(var key in this.info.list[k]){
								var temp = this.info.list[k][key].pl_cn_name;
								this.info.list[k][key].pl_cn_name = this.info.list[k][key].pl_en_name;
								this.info.list[k][key].pl_en_name = temp;
							}
						}
					}
				});
			this.w = [
				{label:this.langs.stats_Today+this.langs.stats_Score,value:"topar"},
				{label:this.langs.stats_Today1+this.langs.stats_Putts,value:"str_putts_pround"},
				{label:this.langs.stats_Drive+this.langs.stats_Accuracy,value:"str_driving_accuracy"},
				{label:this.langs.stats_Bunker+this.langs.stats_Save,value:"str_sand_saves"},
				{label:this.langs.stats_Average+this.langs.stats_DrivingDistance,value:"str_driving_distance"},
				{label:this.langs.stats_Par+this.langs.stats_GIR,value:"str_gir"},
				{label:this.langs.stats_PerRound+this.langs.stats_OnePutt,value:"str_one_putts"},
				{label:this.langs.stats_GIR1+this.langs.stats_AveragePutting,value:"str_putts_pgir"},
				{label:this.langs.stats_Scrambling+this.langs.stats_Save1,value:"str_scramble"}
			];
			for(var k in this.w){
				this.typelist[this.w[k].value] = this.w[k].label;
			}
			document.body.addEventListener('click', (e) => {
				// console.log(e.target)
				this.azshow = false;
			});
		},
		watch: {
			radio: function() {
				this.sorts_value = '';
			},
			sorts_value: function(newValue, oldValue) {
				var arr = newValue.split("|");
				console.log(newValue, oldValue, arr);
				if (arr[0] == '') {
					console.log('排序取消');
					this.info.list = JSON.parse(JSON.stringify(this.oinfo.list));
				} else if (arr[0] == oldValue) {
					console.log('排序2', this.radio);
					if(arr[0] == "str_driving_accuracy"||arr[0] == "str_sand_saves"||arr[0] == "str_driving_distance"||arr[0] == "str_gir"||arr[0] == "str_scramble"){
						this.info.list[this.radio].sort((a, b) => {
							if(!a[arr[0]]&&b[arr[0]]){
								return 1;
							}else if(a[arr[0]]&&!b[arr[0]]){
								return -1;
							}
							return a[arr[0]] - b[arr[0]];
						});
					}else{
						this.info.list[this.radio].sort((a, b) => {
							if(!a[arr[0]]&&b[arr[0]]){
								return 1;
							}else if(a[arr[0]]&&!b[arr[0]]){
								return -1;
							}
							return b[arr[0]] - a[arr[0]];
						});
					}
				} else {
					console.log('排序1', this.radio);
					if(arr[0] == "str_driving_accuracy"||arr[0] == "str_sand_saves"||arr[0] == "str_driving_distance"||arr[0] == "str_gir"||arr[0] == "str_scramble"){
						this.info.list[this.radio].sort((a, b) => {
							if(!a[arr[0]]&&b[arr[0]]){
								return 1;
							}else if(a[arr[0]]&&!b[arr[0]]){
								return -1;
							}
							return b[arr[0]] - a[arr[0]];
						});
					}else{
						this.info.list[this.radio].sort((a, b) => {
							if(!a[arr[0]]&&b[arr[0]]){
								return 1;
							}else if(a[arr[0]]&&!b[arr[0]]){
								return -1;
							}
							return a[arr[0]] - b[arr[0]];
						});
					}
				}
			},
			azshow(newVal,oldVal){
				var width = document.body.clientWidth;
				if(width<=750 && newVal == true){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
			}
		},
		methods: {
			setbannershow(abs, isshow) {
				console.log(abs,isshow,'ssssssssssssssss');
				this.bannershow[abs] = isshow;
			},
			sorts(flag) {
				if (this.sorts_value == flag) {
					this.sorts_value = flag + '|dao';
				} else if (this.sorts_value == flag + '|dao') {
					this.sorts_value = "";
				} else {
					this.sorts_value = flag;
				}

			},
			aliclickshowhide($s) {
				if ($s == '1') {
					this.show = '1';
				} else {
					this.show = '0';
				}
			},
			is_weixn() {

				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {

					return true;

				}
				return false;

			},
			Douwn() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				let url = domain + '/index.php/Home/Match/expPersonalAllInfoPdf/match_id/' + this.info.info.ID + '/lang/' +
					lang + '/rid/' + this.radio;
				// let url = domain+'/index.php/Home/Match/ExpResult/year/'+this.info.info.year+'/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio+'/mt_round/'+this.info.info.mt_round_num+'';
				window.open(url, '_blank');
				return false;
				// console.log(this.$refs.pdf);
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('技术统计','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			changeazshow(){
				this.azshow = !this.azshow;
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0,parent-100)
				});
			},
			changetype(type){
				this.type = type;
				this.azshow = false;
				this.sorts_value = "";
			},
			unlock(){
				document.body.style.overflow = 'unset'
			}
		}
	}
</script>

<style scoped="scoped">
	.banner {
		width: 18.5rem;
		height: 1.85rem;
		overflow: hidden;
	}
	
	.banner img {
		width: 100%;
		height: 1.85rem;
	}
	
	.lf_2 a .headlogo {
		margin-top: 0.35rem;
		display: none;
	}
	
	.headlogo img {
		width: 1.5rem;
		height: 0.5rem;
	
	}
	.lf_group_tit_left {
		margin-top: 0.2rem;
	}

	.lf_match_info_box_r {
		display: flex;
		justify-content: flex-end;
	}

	.lf_course_bogo {
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}

	.lf_course_bogo_des {
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}

	.lf_course_name_data_box {
		background-color: #FAFAFA;
		margin-top: 0.3rem;
	}

	.lf_tec_download {
		width: 100%;
		color: #C0153E;
		font-size: 0.2rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 0 auto;
	}

	.lf_tec_download div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_tec_download img {
		width: 0.32rem;
		height: 0.32rem;
		margin-left: 0.2rem;
		margin-right: 0.2rem;

	}

	.lf_hide_select {
		display: none !important;
	}

	.lf_course_name_data_tit {
		width: 96%;
		border-bottom: 0.01rem solid rgba(219,219,219);
		color: #666666;
		font-size: 0.24rem;
		height: 0.9rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	.lf_course_name_data_con:nth-child(odd) {
	            background: #f7f7f7;
	        }
	.lf_course_name_data_con {
		width: 96%;
		color: #000;
		border-bottom: 0.01rem solid rgba(219,219,219);
		font-size: 0.28rem;
		height: 1rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_tit div,
	.lf_course_name_data_con div {
		width: 7%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-direction: column;
		position: relative;
	}
	.lf_cn_name_{
		font-size: 0.28rem;
	}
	.lf_en_name_{
		font-size: 0.24rem;
	}
	.lf_course_name_data_tit div{
		cursor: pointer;
	}
	.lf_course_name_data_tit .lf_3 {
		cursor: unset;
	}
	.lf_course_name_data_tit .lf_2 {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row !important;
		cursor: unset;
		width: 3rem;
	}
	.lf_course_name_data_tit .lf_1 {
		cursor: unset;
	}
	.lf_2 a{
		flex-grow: 1;
	}
	.lf_2 a div{
		width: 100%;
	}
	.lf_2 .center_name{
		flex-grow: 1;
		height: 1.2rem;
		display: flex;
		flex-direction:column;
		
	}
	.lf_2 .center_name div{
		display: flex;
		align-items: center;
		flex-direction: row;
	}
	.lf_course_name_data_con .lf_2 {
		justify-content: flex-start;
		width: 2rem;
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		overflow: hidden;
	}

	.lf_2 img {
		width: 0.4rem;
	
		margin-right: 10px;
	}
	.lf_4,.lf_5{
		width: 5.5% !important;
		cursor: pointer;
	}
	.lf_6,.lf_7,.lf_8,.lf_9,.lf_10 {
		width: 7% !important;
		cursor: pointer;
	}
	.lf_11,
	.lf_12 {
		width: 10% !important;
		cursor: pointer;
	}

	.lf_select  {
		color: #C0153E;
		font-weight: bold;
		cursor: pointer;
	}
	.center_name div{
		display: flex;
		width: 100%;
		align-items: unset;
		justify-content: unset;
	}
	.az{
		display: none;
	}
	.lf_typeshow,.lf_typehide{
		display: flex;
	}
	.lf_up_d_img{
		position: absolute;
		right: 0.05rem;
		width: 0.22rem;
		height: 0.22rem;
	}
	@media screen and (max-width: 768px) {


		/* .lf_course_name_data_tit div:nth-of-type(n+5),
		.lf_course_name_data_con div:nth-of-type(n+5) {
			display: none !important;
		} */

		.lf_course_name_data_tit div,
		.lf_course_name_data_con div {
			width: 25%;
		}

		.lf_tec_download,
		.lf_course_name_data_tit {
			height: 0.7rem;
		}
		.lf_course_name_data_con .lf_1{
			width: 15%;
		}
		.lf_course_name_data_tit .lf_1{
			width: 15%;
		}
		.lf_course_name_data_con .lf_4,.lf_course_name_data_tit .lf_4,
		.lf_course_name_data_con .lf_5,.lf_course_name_data_tit .lf_5,
		.lf_course_name_data_con .lf_6,.lf_course_name_data_tit .lf_6,
		.lf_course_name_data_con .lf_7,.lf_course_name_data_tit .lf_7,
		.lf_course_name_data_con .lf_8,.lf_course_name_data_tit .lf_8,
		.lf_course_name_data_con .lf_9,.lf_course_name_data_tit .lf_9,
		.lf_course_name_data_con .lf_10,.lf_course_name_data_tit .lf_10,
		.lf_course_name_data_con .lf_11,.lf_course_name_data_tit .lf_11,
		.lf_course_name_data_con .lf_12,.lf_course_name_data_tit .lf_12
		{
			width: 20%!important;
		}
		.lf_hide_select {
			display: unset !important;
		}
		.az{
			display: block;
		}
		.lf_typeshow{
			display: flex;
		}
		.lf_typehide{
			display: none !important;
		}
		.banner{
			width: 100%;
			height: 0.75rem;
			overflow: hidden;
			margin: 0.2rem auto;
		}
		.lf_2 a .headlogo {
			margin-top: 0.35rem;
			display: none;
		}
		.lf_course_name_data_con .lf_2{
			width: 3rem;
		}
	}
</style>