<template>
    <navs></navs>
    <Btn cd="js"></Btn>
    <el-row type="flex" justify="center" >
        <el-col :xs="24" :sm="24" :md="24">
            <div class="lf_footer1_tit_box">
                <div class="lf_foot_tit_text1">技术统计</div>
                <div class="lf_foot_tit_line"></div>
                <div class="lf_foot_tit_text2">Technical Statistics</div>
            </div>
        </el-col>
    </el-row>
	<el-row type="flex" justify="center">
	    <el-col :xs="24" :sm="16" :md="16">
			<el-select v-model="year" class="m-2 lf_center_box" :placeholder="langs.PleaseSelecttheYear" size="large" style="width:200px;" @change="changeyear">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
			</el-select>
	        <div class="lf_News_list_box">
				<div class="lf_foreach_div"  v-for="(item,index) in list">
					<div class="lf_rank_list_tit_box">
					<el-col :xs="16" :sm="12" :md="12">
						<div class="d_title">{{item.title}}</div>
					</el-col>
					<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;justify-content: flex-end;">
						<router-link target="_blank" :to="{path:'/TecStatisticsinfo',query:{year:year,statstype:item.type,lang:systemlang}}">
							<div class="d_title_r">
								<div class="lf_go_more">{{langs.More}} &nbsp;<img src="../../assets/images/u107p002.png" alt=""></div>
							</div>
						</router-link>
					</el-col>
					</div>
					<div class="lf_player_logo_box">
						<div class="lf_player_logo_box_1">
							<img class="lf_huangguan" v-if="item.first.pl_photo_img != ''" src="../../assets/images/u1584.png" alt="">
							<img class="lf_touxiang" v-if="item.first.pl_photo_img != ''" :src="item.first.pl_photo_img" alt="">
						</div>
						<div class="lf_player_logo_box_2">
							{{item.first.pl_name}} 
							<img :src="item.first.pl_country_img" alt="">
						</div>
					</div>
					<div class="lf_player_pos_box">
						{{item.field[0]}}/ <span>{{item.first.num}}</span>
					</div>
					<div class="lf_pos_left_box">
						<div class="lf_rank_box_tit">
							<div class="lf_rank_1">{{langs.text_ranking}}</div>
							<div class="lf_rank_2">{{langs.text_pl_country}}</div>
							<div class="lf_rank_3">{{langs.text_player}}</div>
							<div class="lf_rank_4">{{item.field[0]}}</div>
							<div class="lf_rank_5" v-if="item.field[1]">{{item.field[1]}}</div>
						</div>
						<div class="lf_rank_box_con" v-for="(item1,index) in item.rank2to5" :key="index">
							<div class="lf_rank_1">{{index+2}}</div>
							<div class="lf_rank_2"><img :src="item1.pl_country_img" alt=""></div>
							<div class="lf_rank_3">{{item1.pl_name}}</div>
							<div class="lf_rank_4">{{item1.num}}</div>
							<div class="lf_rank_5" v-if="item.field[1]">{{item1.matchnum}}</div>
						</div>
					</div>
				</div>
	        </div>
	    </el-col>
	</el-row>
    <Footer></Footer>
    <Footer1></Footer1>
</template>

<script>
    // @ is an alias to /src
    import navs from "../../components/nav.vue";
    import Btn from "../../components/Btn.vue";
    import Footer from "../../components/Footer.vue";
    import Footer1 from "../../components/Footer1.vue";
    import axios from '../../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from "../../lang";
import { readlink } from "fs";

    //自动轮播的配置
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
				msgdata:[
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
				],
				options: [{
						value: 'Option1',
						label: 'Option1',
					},
					{
						value: 'Option2',
						label: 'Option2',
					},
					{
						value: 'Option3',
						label: 'Option3',
					},
					{
						value: 'Option4',
						label: 'Option4',
					},
					{
						value: 'Option5',
						label: 'Option5',
					},
				],
				list:[]
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
            Footer,
            Footer1,
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
			var year = (new Date()).getFullYear();
			var yearoptions = [];
			for(var i=year;i>=2010;i=i-1){
				yearoptions.push({"value":i,"label":i})
			}
			this.options = yearoptions;
			this.year = year;
			this.getlist(year);
        },
        methods: {
			getlist:function(year){
				var that = this;
				axios('tecstatistics/getstatslist',{year:year})
				.then((res)=>{
					var list = res.data.data.data;
					for(var key in list){
						list[key].first = {};
						list[key].rank2to5 = [];
						for(var k in list[key].list){
							if(getSystemLang()=="cn"){
								list[key].list[k]["pl_name"] = list[key].list[k]["pl_cn_name"];
							}else{
								list[key].list[k]["pl_name"] = list[key].list[k]["pl_en_name"];
							}
							if(k==0){
								list[key].first = list[key].list[k];
							}
							if(k>=1&&k<=4){
								list[key].rank2to5.push(list[key].list[k]);
							}
							if(k>4){
								break;
							}
						}
					}
					console.log(list);
					this.list = list;
				})
			},
			changeyear:function(year){
				this.year = year;
				this.getlist(year);
			}
        }

    }
</script>


<style scoped="scoped">
    .lf_footer1:nth-last-of-type(1) {
        border-bottom: none;
    }
	.lf_rank_list_tit_box{
		display: flex;
		height: 0.54rem;
		background-color: rgba(250, 250, 250, 1);
	}
    .d_title_r{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}
	.lf_go_more{
		height: 0.4rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.18rem;
		color: #C0153E;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(192, 21, 62, 1);
		border-radius: 2px;
		margin-left: 10px;
		padding: 0 0 0 0.1rem;
		margin-right: 0.2rem;
	}
	.lf_go_more img{
		transform: rotate(180deg);
		width: 0.33rem;
		height: 0.3rem;
	}
    .lf_News_list_box {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0.3rem 0;
		flex-wrap: wrap;
		justify-content: space-between;
    }
    .lf_footer1_tit_box {
        height: 1.4rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .lf_footer1_tit_box .lf_foot_tit_text1 {
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.26rem;
        color: #C0153E;
    }
    
    .lf_footer1_tit_box .lf_foot_tit_text2 {
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.15rem;
        color: #C0153E;
    }
    
    .lf_footer1_tit_box .lf_foot_tit_line {
        width: 2.2rem;
        height: 3px;
        background-color: #C0153E;
        margin: 4px 0;
    }
    .d_title{
		margin-left: 0.2rem;
    	color: rgb(192, 21, 62);
    	font-size: 0.2rem;
    	font-family: '微软雅黑';
    	display: flex;
		align-items: center;
    	padding-right: 30px;
		height: 0.54rem;
    }
	.d_title span{
		font-size: 0.14rem;
		margin-top: 0.04rem;
	}
	.lf_foreach_div{
		width: 49%;
		border: 1px solid #F5F5F5;
		border-radius: 0.05rem;
		margin-bottom: 0.3rem;
	}
    .lf_pos_left_box{
		width: 100%;
	}
	.lf_rank_box_tit{
		margin: 0 auto;
		display: flex;
		align-items: center;
		color: #333;
		height: 0.55rem;
		margin-bottom: 0.05rem;
		font-size: 0.16rem;
		width: 96%;
	}
	.lf_rank_box_con{
		margin: 0 auto;
		display: flex;
		align-items: center;
		color: #999;
		height: 0.46rem;
		font-size: 0.16rem;
		width: 96%;
	}
	.lf_rank_1{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_2{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_3{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-grow: 1;
	}
	.lf_rank_4{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_5{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_2 img{
		width: 20px;
		height: 14px;
	}
	.lf_rank_3 img{
		width: 24px;
		height: 24px;
	}
	.lf_player_logo_box{
		width: 96%;
		border-bottom: 1px solid #EFEFEF;
		margin: 0 auto;
	}
	.lf_player_logo_box_1{
		width: 1.2rem;
		height: 1.2rem;
		position: relative;
		margin: 0.4rem auto 0.1rem;
	}
	.lf_touxiang{
		width: 1.2rem;
		height: 1.2rem;
		border-radius: 50%;
		border: 1px solid #EFEFEF;
	}
	.lf_huangguan{
		width: 0.6rem;
		height: 0.6rem;
		transform: rotate(-20deg);
		position: absolute;
		left: -0.18rem;
		top: -0.2rem;
	}
	.lf_player_logo_box_2{
		font-size: 0.2rem;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.2rem 0;
	}
	.lf_player_logo_box_2 img{
		width: 20px;
		height: 14px;
		margin-left: 0.1rem;
	}
	.lf_player_pos_box{
		height: 0.8rem;
		border-bottom: 1px solid #EFEFEF;
		font-size: 0.16rem;
		width: 96%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_player_pos_box span{
		font-size: 0.16rem;
		color: #C0153E;
		margin-left: 0.04rem;
	}
    @media screen and (max-width: 768px) {
        .d_title_r{
			margin-right: 0.1rem;
		}
		.lf_rank_box_tit{
			font-size: 0.24rem;
		}
		.lf_footer1_tit_box{
			margin-top: 0.5rem;
		}
		.lf_rank_box_tit{
		width: 100%;
		}
		.lf_rank_box_con{
		width: 100%;
		font-size: 0.24rem;
		}
		.lf_rank_box_tit div:nth-last-of-type(1),.lf_rank_box_con div:nth-last-of-type(1){
			margin-right: 0.1rem;
		}
		.lf_foreach_div{
			width: 96%;
			margin: 0 auto 0.3rem;
		}
		.lf_rank_1{
			width: 18%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_2{
			width: 18%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_3{
			width: 18%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			flex-grow: 1;
		}
		.lf_rank_4{
			width: 18%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			
		}
		.lf_rank_5{
			width: 18%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_player_logo_box_2{
			font-size: 0.3rem;
		}
		.lf_go_more{
			font-size: 0.24rem;
		}
		.d_title,.d_title span{
			font-size: 0.26rem;
		}
		.lf_player_pos_box{
			font-size: 0.24rem;
		}
		.lf_player_pos_box span{
			font-size: 0.24rem;
		}
		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}
		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}
		.lf_center_box{
			width: 96%!important;
		}
    }
</style>
